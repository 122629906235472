import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const UploadFile = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="upload_file_FILL0_wght500_GRAD0_opsz48 1">
        <path
          id="Vector"
          d="M11.3024 13.8652V18.1659C11.3024 18.3779 11.3743 18.5556 11.5181 18.699C11.6619 18.8424 11.8407 18.9141 12.0544 18.9141C12.2682 18.9141 12.4459 18.8424 12.5875 18.699C12.7292 18.5556 12.8 18.3779 12.8 18.1659V13.8652L14.3353 15.4005C14.3824 15.4476 14.506 15.4295 14.706 15.3462C14.906 15.2629 15.0945 15.1879 15.2715 15.1212C15.4485 15.0545 15.5703 15.026 15.637 15.0355C15.7037 15.045 15.6203 15.1677 15.387 15.4034C15.537 15.2583 15.6068 15.0843 15.5965 14.8814C15.5862 14.6786 15.508 14.5022 15.362 14.3522L12.6028 11.6739C12.5099 11.583 12.4132 11.5168 12.3128 11.4753C12.2123 11.4338 12.1071 11.413 11.9971 11.413C11.8871 11.413 11.7829 11.4338 11.6844 11.4753C11.5859 11.5168 11.4902 11.583 11.3973 11.6739L8.66906 14.4022C8.51508 14.5572 8.43816 14.7348 8.43831 14.9348C8.43846 15.1348 8.51538 15.3115 8.66906 15.4648C8.81798 15.61 8.9927 15.6807 9.19324 15.6769C9.39379 15.6731 9.56906 15.5982 9.71906 15.4522L11.3024 13.8652ZM5.55384 22.1495C5.09385 22.1495 4.69498 21.9806 4.35721 21.6428C4.01946 21.3051 3.85059 20.9062 3.85059 20.4462V3.55379C3.85059 3.09218 4.01946 2.6919 4.35721 2.35297C4.69498 2.01402 5.09385 1.84454 5.55384 1.84454H13.8761C14.1119 1.84454 14.3366 1.89219 14.5502 1.98749C14.7639 2.08278 14.9473 2.20705 15.1006 2.36032L19.6397 6.89944C19.793 7.05271 19.9173 7.23617 20.0125 7.44982C20.1078 7.66347 20.1555 7.88817 20.1555 8.12392V20.4462C20.1555 20.9062 19.986 21.3051 19.6471 21.6428C19.3081 21.9806 18.9079 22.1494 18.4462 22.1494L5.55384 22.1495ZM13.7212 7.35217V3.55379H5.55384V20.4462H18.4462V8.20379H14.5729C14.3325 8.20379 14.1304 8.12196 13.9667 7.95829C13.8031 7.79461 13.7212 7.59257 13.7212 7.35217Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
