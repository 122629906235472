import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ExpandLess = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=expand_less, Size=24">
        <path
          id="Vector"
          d="M12 10.5625L7.67174 14.8908C7.50181 15.0607 7.29872 15.1498 7.06249 15.1582C6.82626 15.1665 6.62317 15.0857 6.45324 14.9158C6.28332 14.7458 6.19836 14.5428 6.19836 14.3065C6.19836 14.0703 6.28332 13.8672 6.45324 13.6973L11.3973 8.75325C11.4886 8.66195 11.5839 8.59565 11.6831 8.55435C11.7824 8.51305 11.888 8.4924 12 8.4924C12.1119 8.4924 12.2176 8.51305 12.3168 8.55435C12.4161 8.59565 12.5114 8.66195 12.6027 8.75325L17.5217 13.6723C17.6917 13.8422 17.7808 14.0411 17.7891 14.269C17.7974 14.4969 17.7167 14.6958 17.5467 14.8658C17.3768 15.0357 17.1737 15.1207 16.9375 15.1207C16.7013 15.1207 16.4982 15.0357 16.3282 14.8658L12 10.5625Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
