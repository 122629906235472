import { useEffect, useState } from "react";

export const useScreenDimension = () => {
  const [dimension, setDimension] = useState("desktop");

  const getDimensions = () => {
    let dimension = "desktop";
    if (window.innerWidth < 768) {
      dimension = "mobile";
    }
    if (window.innerWidth > 767 && window.innerWidth < 1024) {
      dimension = "tablet";
    }
    if (window.innerWidth > 1024 && window.innerWidth < 1280) {
      dimension = "laptop";
    }
    setDimension(dimension);
  };

  useEffect(() => {
    window.addEventListener("resize", getDimensions);
    return () => {
      window.removeEventListener("resize", getDimensions);
    };
  });

  useEffect(() => {
    getDimensions();
  }, []);

  return {
    isMobile: dimension === "mobile",
    isTablet: dimension === "tablet",
    isLaptop: dimension === "laptop",
    isDesktop: dimension === "desktop",
  };
};

export const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 640; // sm
  const isTablet = width >= 640 && width < 768; // md
  const isLaptop = width >= 768 && width < 1024; // lg
  const isDesktop = width >= 1024 && width < 1280; // xl
  const isLargeDesktop = width >= 1280; // 2xl

  return {
    sm: isMobile,
    md: isTablet,
    lg: isLaptop,
    xl: isDesktop,
    "2xl": isLargeDesktop,
  };
};
