import { colorsV2 } from "constants/colors";
import { useMemo } from "react";
import type { SvgIconProps } from "types/Icon";

type WalletCardProps = SvgIconProps & {
  variant: "credit" | "debit";
};

/**
 * @deprecated Use from '@_icons' instead
 */
export const VisaCardWithBackground = ({
  size,
  fill = colorsV2.text[1],
  variant,
  ...rest
}: WalletCardProps) => {
  const colors = useMemo(() => {
    if (variant === "debit") {
      return {
        circleFill: colorsV2.primary[3],
        cardFill: colorsV2.border[4], // TODO: Does not make sense to use border color here
        mamoIconFill: colorsV2.surface[2],
      };
    }
    return {
      circleFill: colorsV2.primary[3],
      cardFill: "#EFEFEF", // One off, but this is the color of the card background in the designs
      mamoIconFill: "#000000",
    };
  }, [variant]);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="40" height="40" rx="20" fill={colors.circleFill} />
      <g filter="url(#filter0_d_2057_1060)">
        <rect
          x="12.575"
          y="8.5"
          width="15.12"
          height="24"
          rx="0.96"
          fill={colors.cardFill}
          shapeRendering="crispEdges"
        />
        <g clipPath="url(#clip0_2057_1060)">
          <g clipPath="url(#clip1_2057_1060)">
            <path
              d="M23.281 29.4205L22.5957 31.0556H22.1486L21.8114 29.7507C21.7909 29.6704 21.7731 29.6409 21.7109 29.607C21.6092 29.5519 21.4413 29.5002 21.2936 29.4681L21.3037 29.4205H22.0234C22.1151 29.4205 22.1976 29.4816 22.2184 29.5872L22.3965 30.5333L22.8367 29.4205H23.281ZM25.0329 30.5217C25.0347 30.0902 24.4361 30.0664 24.4403 29.8736C24.4415 29.815 24.4974 29.7526 24.6197 29.7367C24.6803 29.7287 24.8473 29.7227 25.0366 29.8099L25.1109 29.4631C25.0092 29.4262 24.8782 29.3906 24.7153 29.3906C24.2972 29.3906 24.003 29.6129 24.0005 29.9311C23.9978 30.1665 24.2105 30.2979 24.3708 30.3761C24.5357 30.4562 24.591 30.5076 24.5903 30.5793C24.5892 30.689 24.4589 30.7374 24.3371 30.7393C24.1245 30.7426 24.0011 30.6818 23.9028 30.636L23.8261 30.9942C23.9249 31.0396 24.1074 31.0791 24.2965 31.0811C24.7408 31.0811 25.0315 30.8616 25.0329 30.5217ZM26.1368 31.0556H26.528L26.1865 29.4205H25.8255C25.7443 29.4205 25.6758 29.4678 25.6455 29.5405L25.0108 31.0556H25.4549L25.5431 30.8113H26.0858L26.1368 31.0556L26.1368 31.0556ZM25.6649 30.4762L25.8875 29.8623L26.0156 30.4762H25.6649H25.6649ZM23.8854 29.4205L23.5356 31.0556H23.1127L23.4625 29.4205H23.8854Z"
              fill={colors.mamoIconFill}
            />
          </g>
        </g>
        <path
          d="M20.1492 16.3984C19.0231 16.3984 17.9734 16.8463 17.1931 17.6598C16.4122 18.4742 16.008 19.5452 16.0552 20.6759C16.1438 22.8021 17.8779 24.5258 20.0029 24.5993C20.0521 24.6009 20.1013 24.6019 20.1505 24.6019C21.2219 24.6019 22.2295 24.1957 23.0023 23.4492C23.8098 22.6692 24.2547 21.6219 24.2547 20.5003C24.2547 18.2389 22.4129 16.3988 20.1492 16.3988V16.3984ZM23.3058 20.6641C23.2251 22.2594 21.9126 23.5722 20.3172 23.6526C19.4247 23.6976 18.5506 23.3622 17.9206 22.7322C17.2906 22.1022 16.9552 21.2288 17.0002 20.3356C17.0809 18.7403 18.3934 17.4274 19.9888 17.347C20.8822 17.3018 21.7554 17.6374 22.3854 18.2674C23.0154 18.8974 23.3507 19.7709 23.3058 20.6641Z"
          fill={colors.mamoIconFill}
        />
        <path
          d="M20.1531 22.9508C21.4764 22.9508 22.5566 21.8896 22.5875 20.5735H17.7188C17.7496 21.8896 18.8298 22.9508 20.1531 22.9508Z"
          fill={colors.mamoIconFill}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2057_1060"
          x="9.26467"
          y="8.5"
          width="21.7407"
          height="30.6207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.31034" />
          <feGaussianBlur stdDeviation="1.65517" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2057_1060"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2057_1060"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_2057_1060">
          <rect
            width="5.85937"
            height="2.7"
            fill="white"
            transform="translate(20.9811 28.875)"
          />
        </clipPath>
        <clipPath id="clip1_2057_1060">
          <rect
            width="5.23437"
            height="1.69046"
            fill="white"
            transform="translate(21.2936 29.3906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
