import {
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { isNil } from "lodash";
import moment from "moment";

import { fetchDefaultLinkSetting } from "./shared/useGetDefaultLinkSetting";

import { isNumberDefined } from "helpers/numberUtils";
import { expirationOptions } from "./AddAdvancedSettings/AddExpirationDate";
import {
  type CreateLinkState,
  type IDefaultLinkSettingResponse,
  ProcessingFeeTypes,
  type TCurrentStepMobile,
  type UpdateDataPayload,
} from "./shared/typeDefs";
import {
  type MerchantInfoResponse,
  fetchMerchantInfo,
} from "./shared/useGetMerchantInfo";

const DEFAULT_REQUIRED_FIELDS = ["title", "prefilledAmount"];

export const fetchAndUpdateDefaultLinkSettings = createAsyncThunk(
  "createLink/fetchAndUpdateDefaultLinkSettings",
  async (_, { dispatch }) => {
    try {
      const defaultSettingData: IDefaultLinkSettingResponse =
        await fetchDefaultLinkSetting();

      const {
        fieldConfigurations,
        linkDefaultSetting,
        tabbyAvailability,
        tabbyTermAgreement,
      } = defaultSettingData;

      const merchantInfoData: MerchantInfoResponse = await fetchMerchantInfo();
      const { currentMerchant } = merchantInfoData;
      const { premiumAccess, trialAccess } = currentMerchant;

      const formattedFieldConfigurations = {
        displayTips: fieldConfigurations.tips === "display",
        displayDescription: fieldConfigurations.description === "display",
        displayInvoiceDate: fieldConfigurations.invoiceDate === "display",
        displayInvoiceNumber: fieldConfigurations.invoiceNumber === "display",
      };

      const requiredFields = [
        ...(formattedFieldConfigurations.displayInvoiceDate
          ? ["invoiceDate"]
          : []),
        ...(formattedFieldConfigurations.displayInvoiceNumber
          ? ["invoiceNumber"]
          : []),
      ];

      let defaultExpirationPeriod = null;
      let defaultExpirationDate = null;
      let defaultIncludeExpirationDate = false;

      if (
        !isNil(linkDefaultSetting.expirationPeriod) &&
        !isNil(expirationOptions[linkDefaultSetting.expirationPeriod])
      ) {
        defaultIncludeExpirationDate = true;
        defaultExpirationPeriod = linkDefaultSetting.expirationPeriod;
        defaultExpirationDate =
          linkDefaultSetting.expirationPeriod ===
          expirationOptions["after-7-days"]
            ? moment(new Date()).add(7, "days").format("DD/MM/YYYY")
            : moment(new Date()).add(30, "days").format("DD/MM/YYYY");
      }

      dispatch(
        updateData({
          data: {
            capacity:
              (premiumAccess || trialAccess) &&
              isNumberDefined(linkDefaultSetting.capacity)
                ? linkDefaultSetting.capacity
                : null,
            currency: linkDefaultSetting.currency ?? "AED",
            customerDetails: linkDefaultSetting.enableCustomerDetails ?? false,
            description: linkDefaultSetting.description ?? null,
            includeCapacity: !!(
              (premiumAccess || trialAccess) &&
              isNumberDefined(linkDefaultSetting.capacity)
            ),
            includeFee: !!(
              isNumberDefined(linkDefaultSetting.processingFeeAmount) ||
              isNumberDefined(linkDefaultSetting.processingFeePercentage)
            ),
            includeQrCode: linkDefaultSetting.enableQrCode ?? false,
            includeQuantity: linkDefaultSetting.enableQuantity ?? false,
            includeRedirectUrl: !!linkDefaultSetting.returnUrl,
            includeTabbyBNPL: linkDefaultSetting.enableTabby ?? false,
            includeTerms: !!linkDefaultSetting.termsConditionsUrl,
            includeTips: linkDefaultSetting.enableTips ?? false,
            includeExpirationDate: defaultIncludeExpirationDate,
            expirationPeriod: defaultExpirationPeriod,
            expirationDate: defaultExpirationDate,
            message: linkDefaultSetting.enableMessage ?? false,
            prefilledAmount: isNumberDefined(linkDefaultSetting.prefilledAmount)
              ? linkDefaultSetting.prefilledAmount
              : null,
            processingFeeAmount: isNumberDefined(
              linkDefaultSetting.processingFeeAmount,
            )
              ? linkDefaultSetting.processingFeeAmount
              : null,
            processingFeePercentage: isNumberDefined(
              linkDefaultSetting.processingFeePercentage,
            )
              ? linkDefaultSetting.processingFeePercentage
              : null,
            processingFeeType: isNumberDefined(
              linkDefaultSetting.processingFeeAmount,
            )
              ? ProcessingFeeTypes.FIXED
              : ProcessingFeeTypes.PERCENTAGE,
            returnUrl: linkDefaultSetting.returnUrl ?? null,
            title: linkDefaultSetting.title ?? null,
            termsConditionsUrl: linkDefaultSetting.termsConditionsUrl ?? null,
            tabbyAvailability: tabbyAvailability ?? false,
            tabbyTermAgreement: tabbyTermAgreement ?? false,
          },
        }),
      );
      dispatch(updateFieldConfigurations(formattedFieldConfigurations));

      dispatch(
        updateRequiredFields([...DEFAULT_REQUIRED_FIELDS, ...requiredFields]),
      );

      dispatch(
        updatePremiumAndTrialAccess({
          premiumAccess,
          trialAccess,
        }),
      );

      dispatch(
        updateHoldAndChargeLaterPeriod(
          linkDefaultSetting.holdAndChargeLaterPeriod ?? 7,
        ),
      );
    } catch (error) {
      console.error("createLinkSlice", error);
    }
  },
);

export const createLinkSlice = createSlice({
  name: "createLink",
  initialState: {
    currentStepMobile: "add-title-description",
    currentTab: "details",
    data: {
      capacity: null,
      currency: "AED",
      customerDetails: false,
      description: null,
      includeCapacity: false,
      includeChargeCardLater: false,
      includeFee: false,
      includeInternalNote: false,
      includeQrCode: false,
      includeQuantity: false,
      includeRedirectUrl: false,
      includeTabbyBNPL: false,
      includeTerms: false,
      includeTips: false,
      internalNote: null,
      invoiceDate: null,
      invoiceNumber: null,
      linkType: null,
      message: false,
      prefilledAmount: null,
      processingFeeAmount: null,
      processingFeePercentage: null,
      processingFeeType: ProcessingFeeTypes.PERCENTAGE,
      returnUrl: null,
      tabbyAvailability: false,
      tabbyTermAgreement: false,
      termsConditionsUrl: null,
      title: null,
    },
    fieldConfigurations: {
      displayDescription: true,
      displayInvoiceDate: false,
      displayInvoiceNumber: false,
      displayTips: true,
    },
    hasErrorInForm: false,
    isSubmitDisabled: true,
    loading: false,
    premiumAccess: false,
    requiredFields: DEFAULT_REQUIRED_FIELDS,
    trialAccess: false,
    holdAndChargeLaterPeriod: 7,
  } as CreateLinkState,
  reducers: {
    nextStep: (state) => {
      const stepTransitions: Record<TCurrentStepMobile, TCurrentStepMobile> = {
        "add-title-description": "set-amount",
        "set-amount": "customize",
        customize: "add-title-description",
      };
      state.currentStepMobile = stepTransitions[state.currentStepMobile];
    },
    // Handle state management if there is an error in form fields in any tab
    onErrorInForms: (
      state,
      action: PayloadAction<CreateLinkState["isSubmitDisabled"]>,
    ) => {
      // isSubmitDisabled looks at errors first and then looks at required fields
      // if there is any error, it returns true
      // if no errors but one of the required fields is not filled, then it returns true
      state.isSubmitDisabled = action.payload;

      // Looks only at errors, required for mobile view
      // On mobile view, our UI is split into subsections, so not all requiredFields will
      // in redux be filled but the continue cta should work
      state.hasErrorInForm = action.payload;
    },
    previousStep: (state) => {
      const stepTransitions: Record<TCurrentStepMobile, TCurrentStepMobile> = {
        "add-title-description": "customize",
        "set-amount": "add-title-description",
        customize: "set-amount",
      };
      state.currentStepMobile = stepTransitions[state.currentStepMobile];
    },
    updateCurrentTab: (
      state,
      action: PayloadAction<CreateLinkState["currentTab"]>,
    ) => {
      state.currentTab = action.payload;
    },
    updateData: (state, action: PayloadAction<UpdateDataPayload>) => {
      state.data = {
        ...state.data,
        ...action.payload.data,
      };
    },
    updateIsSubmitDisabled: (state, action) => {
      state.isSubmitDisabled = action.payload;
    },
    updateFieldConfigurations: (
      state,
      action: PayloadAction<CreateLinkState["fieldConfigurations"]>,
    ) => {
      state.fieldConfigurations = action.payload;
    },
    updatePremiumAndTrialAccess: (
      state,
      action: PayloadAction<{ premiumAccess: boolean; trialAccess: boolean }>,
    ) => {
      state.premiumAccess = action.payload.premiumAccess;
      state.trialAccess = action.payload.trialAccess;
    },
    updateHoldAndChargeLaterPeriod: (
      state,
      action: PayloadAction<CreateLinkState["holdAndChargeLaterPeriod"]>,
    ) => {
      state.holdAndChargeLaterPeriod = action.payload;
    },
    updateRequiredFields: (state, action) => {
      state.requiredFields = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAndUpdateDefaultLinkSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAndUpdateDefaultLinkSettings.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchAndUpdateDefaultLinkSettings.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  updateIsSubmitDisabled,
  nextStep,
  previousStep,
  onErrorInForms,
  updateCurrentTab,
  updateData,
  updateFieldConfigurations,
  updatePremiumAndTrialAccess,
  updateHoldAndChargeLaterPeriod,
  updateRequiredFields,
} = createLinkSlice.actions;

export default createLinkSlice.reducer;
