// Stylesheets
import "assets/sass/main-dashboard.scss";
import "intl-tel-input/build/css/intlTelInput.css";

// Images
import "assets/icons/intl-card.svg";
import "assets/icons/uae-flag.svg";
import "assets/illustrations/logo-google-sm.svg";
import "assets/illustrations/payment-link-empty-state.png";
import "assets/illustrations/payments-settlements-empty-state.svg";
import "assets/logos/logo-blue.svg";

// Javascript
import "config/axios";
import intlTelInput from "intl-tel-input";
const ReactOnRails = window.ReactOnRails || require("react-on-rails").default;

import BrazeModal from "components/BrazeModal";
import TrialStartModal from "components/Modal/TrialStart";
import { PaymentLinkEdit } from "components/PaymentLinks/Edit";
import ResultScanQrCode from "components/QrCode/ResultScanQrCode";
import { ShareLinkCardRoot } from "components/ShareLinkCard";
// import SubscriberDetailsRoot from "components/SubscriptionsRoot/Subscribers/SubscriberDetailsRoot";
import SuccessPage from "components/shared/SuccessPage";
import { SignUpRoot } from "features/Access/SignUp/SignUpRoot";
import { FirstTimeLoginRoot } from "features/FirstTimeLogin/FirstTimeLoginRoot";

import Forbidden from "pages/Dashboard/Forbidden";
import NotFound from "pages/NotFound";

import { loggerInit } from "init/SentryLogger";

import "../locales/i18n";

const inputs = document.querySelectorAll("input[name=intl_phone]");

for (var i = 0; i < inputs.length; i++) {
  intlTelInput(inputs[i], {
    autoHideDialCode: true,
    autoPlaceholder: "aggressive",
    initialCountry: "ae",
    separateDialCode: false,
    onlyCountries: ["ae"],
    utilsScript:
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.15/js/utils.min.js",
    hiddenInput: "phone",
  });
}

/** PASSWORD VALIDATION */
function checkAndUpdate(password: string, regex: string, identifier: string) {
  const display = document.getElementById(identifier);

  if (password.match(regex)) {
    display?.classList.add("field-message--success");
    return true;
  }

  display?.classList.remove("field-message--success");
  return false;
}

export function onPasswordChange(event: any) {
  const password = event.target.value;

  const length = checkAndUpdate(password, "^.{8,}$", "password-length-status");
  const lowercase = checkAndUpdate(
    password,
    ".*[a-z]",
    "password-lowercase-status",
  );
  const uppercase = checkAndUpdate(
    password,
    ".*[A-Z]",
    "password-uppercase-status",
  );
  const number = checkAndUpdate(password, ".*[0-9]", "password-number-status");
  const special = checkAndUpdate(
    password,
    ".*[#?!@$%^&*-]",
    "password-special-status",
  );

  if (length && lowercase && uppercase && number && special) {
    $("#reset-password-btn").prop("disabled", false);
  } else {
    $("#reset-password-btn").prop("disabled", true);
  }
}

/** FORGOT PASSWORD MODAL */
$(".forgot-password-button").click(function () {
  $("#forgot-password-modal").removeClass("hidden");
  $("body").css("overflow", "hidden");
});

/** CLOSE MODAL */
$(".modal__box__close, .modal__overlay, .close-modal, .reset-link").click(
  function () {
    $(".modal").addClass("hidden");
    $("body").css("overflow", "auto");
  },
);

/** SHOW PASSWORD  */
$(".form-field__password__icon").click(function () {
  $(this).html() == "visibility_off"
    ? $(this).html("visibility")
    : $(this).html("visibility_off");
  if ($(".form-field__password").attr("type") == "password") {
    $(".form-field__password").attr("type", "text");
  } else {
    $(".form-field__password").attr("type", "password");
  }
});

/**
 * This is how react_on_rails can see any components we include in our views using
 * the view helper provided by the library, e.g
 *
 * <%= react_component("HelloWorld", props: @hello_world_props) %>
 *
 * After adding the line above in your view, you would need to include a component in
 * app/javascript/components folder, import the the component here and include in the call
 * to register below
 *
 * NB: Please keep this list in Ascending order
 **/
ReactOnRails.register({
  BrazeModal,

  // SubscriberDetailsRoot, // Added layout directly to the component. Do not remove from here

  FirstTimeLoginRoot, // UI is on the backend
  SignUpRoot, // UI is on the backend

  Forbidden, // TODO: Check with Anis, what do we about this?
  NotFound, // TODO: Check with Anis, what do we about this?

  PaymentLinkEdit, // Tony working on API

  ResultScanQrCode, // Need to check with Dong, Where is this used?
  ShareLinkCardRoot, // Need to check with Dong, no route exists for this
  SuccessPage, // TODO: Check with Dong, no route exists for this
  TrialStartModal, // TODO: Check with Dong, no route exists for this
});

loggerInit();
