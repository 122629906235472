import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Store = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=store, Size=24">
        <path
          id="Vector"
          d="M4.74995 3.79075H19.3C19.5404 3.79075 19.7424 3.87401 19.9061 4.04052C20.0697 4.20704 20.1516 4.41112 20.1516 4.65275C20.1516 4.8944 20.0697 5.09602 19.9061 5.2576C19.7424 5.4192 19.5404 5.5 19.3 5.5H4.74995C4.50955 5.5 4.30751 5.41774 4.14383 5.25323C3.98016 5.08871 3.89833 4.88562 3.89833 4.64397C3.89833 4.40234 3.98016 4.19973 4.14383 4.03613C4.30751 3.87254 4.50955 3.79075 4.74995 3.79075ZM4.87495 20.2033C4.63455 20.2033 4.43251 20.1214 4.26883 19.9578C4.10516 19.7941 4.02333 19.592 4.02333 19.3516V13.7533H3.4282C3.16067 13.7533 2.94551 13.6516 2.78273 13.4484C2.61993 13.2451 2.56752 13.0129 2.6255 12.7516L3.7494 7.67773C3.78672 7.47446 3.88409 7.31069 4.04153 7.18642C4.19896 7.06214 4.37713 7 4.57603 7H19.4549C19.6498 7 19.8259 7.06214 19.9834 7.18642C20.1408 7.31069 20.2382 7.47446 20.2755 7.67773L21.4054 12.7516C21.4594 13.0129 21.405 13.2451 21.2422 13.4484C21.0794 13.6516 20.8642 13.7533 20.5967 13.7533H20.0016V19.3516C20.0016 19.592 19.9193 19.7941 19.7548 19.9578C19.5903 20.1214 19.3872 20.2033 19.1456 20.2033C18.9039 20.2033 18.7023 20.1214 18.5407 19.9578C18.3791 19.7941 18.2983 19.592 18.2983 19.3516V13.7533H13.7766V19.3516C13.7766 19.592 13.6947 19.7941 13.5311 19.9578C13.3674 20.1214 13.1654 20.2033 12.925 20.2033H4.87495ZM5.72658 18.5H12.0733V13.7533H5.72658V18.5ZM4.46463 12.05H19.5603L18.8212 8.70328H5.20375L4.46463 12.05Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
