import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Lightbulb = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=lightbulb, Size=24">
        <path
          id="Vector"
          d="M11.9998 22.275C11.4214 22.275 10.9303 22.0692 10.5267 21.6576C10.123 21.246 9.92124 20.741 9.92124 20.1424H14.0788C14.0788 20.741 13.877 21.246 13.4732 21.6576C13.0694 22.0692 12.5783 22.275 11.9998 22.275ZM8.69366 18.5565C8.46956 18.5565 8.28263 18.4809 8.13286 18.3295C7.98308 18.1782 7.90819 17.9906 7.90819 17.7669C7.90819 17.5449 7.9835 17.359 8.13414 17.2093C8.28475 17.0596 8.47139 16.9848 8.69406 16.9848H15.312C15.533 16.9848 15.7182 17.0611 15.8677 17.2137C16.0171 17.3664 16.0919 17.5537 16.0919 17.7758C16.0919 17.9978 16.0171 18.1835 15.8677 18.3327C15.7182 18.4819 15.533 18.5565 15.312 18.5565H8.69366ZM8.03319 15.4239C6.90927 14.6913 6.01452 13.775 5.34894 12.6749C4.68337 11.5748 4.35059 10.333 4.35059 8.94948C4.35059 6.87728 5.10661 5.08431 6.61866 3.57058C8.13071 2.05686 9.92392 1.3 11.9983 1.3C14.0726 1.3 15.8674 2.05686 17.3826 3.57058C18.8979 5.08431 19.6555 6.87727 19.6555 8.94945C19.6555 10.3313 19.3269 11.5727 18.6696 12.6737C18.0124 13.7746 17.1115 14.6913 15.9669 15.4239H8.03319ZM8.65076 13.7207H15.3678C16.1652 13.1923 16.7943 12.5077 17.2551 11.6671C17.7158 10.8264 17.9462 9.92098 17.9462 8.95078C17.9462 7.31585 17.3637 5.91685 16.1987 4.7538C15.0336 3.59077 13.6341 3.00925 12 3.00925C10.366 3.00925 8.96644 3.59077 7.80139 4.7538C6.63635 5.91685 6.05384 7.31585 6.05384 8.95078C6.05384 9.92098 6.28518 10.8262 6.74786 11.6664C7.21054 12.5065 7.84484 13.1913 8.65076 13.7207Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
