import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Payments = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Icon">
        <path
          id="Vector"
          d="M10.1743 10.0219C9.54929 10.0219 9.01804 9.80314 8.58054 9.36564C8.14304 8.92814 7.92429 8.39689 7.92429 7.77189C7.92429 7.14689 8.14304 6.61564 8.58054 6.17814C9.01804 5.74064 9.54929 5.52189 10.1743 5.52189C10.7993 5.52189 11.3305 5.74064 11.768 6.17814C12.2055 6.61564 12.4243 7.14689 12.4243 7.77189C12.4243 8.39689 12.2055 8.92814 11.768 9.36564C11.3305 9.80314 10.7993 10.0219 10.1743 10.0219ZM4.28191 12.75C3.92778 12.75 3.62558 12.6249 3.37533 12.3747C3.12509 12.1244 2.99998 11.8222 2.99998 11.4681V4.0757C2.99998 3.72316 3.12509 3.42137 3.37533 3.17032C3.62558 2.91928 3.92778 2.79376 4.28191 2.79376H16.0757C16.4269 2.79376 16.7277 2.91928 16.9779 3.17032C17.228 3.42137 17.3531 3.72316 17.3531 4.0757V11.4681C17.3531 11.8222 17.228 12.1244 16.9779 12.3747C16.7277 12.6249 16.4269 12.75 16.0757 12.75H4.28191ZM6.1031 11.5219H14.25C14.25 10.9969 14.4312 10.5531 14.7937 10.1906C15.1562 9.82814 15.6 9.64689 16.125 9.64689V5.89689C15.6 5.89689 15.1562 5.71489 14.7937 5.35089C14.4312 4.9869 14.25 4.5439 14.25 4.02189H6.1031C6.1031 4.5439 5.92185 4.9869 5.55935 5.35089C5.19685 5.71489 4.7531 5.89689 4.2281 5.89689V9.64689C4.7531 9.64689 5.19685 9.82814 5.55935 10.1906C5.92185 10.5531 6.1031 10.9969 6.1031 11.5219ZM14.3613 15.1031H1.92429C1.573 15.1031 1.27227 14.9781 1.02209 14.7279C0.771932 14.4777 0.646851 14.177 0.646851 13.8257V5.88873C0.646851 5.70843 0.708544 5.55691 0.831932 5.43416C0.955319 5.31139 1.10763 5.25001 1.28887 5.25001C1.47009 5.25001 1.62131 5.31139 1.74251 5.43416C1.86369 5.55691 1.92429 5.70843 1.92429 5.88873V13.8257H14.3613C14.5416 13.8257 14.6931 13.8874 14.8158 14.0108C14.9386 14.1342 15 14.2865 15 14.4677C15 14.6489 14.9386 14.8002 14.8158 14.9213C14.6931 15.0425 14.5416 15.1031 14.3613 15.1031Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
