import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Slack = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      fill={fill}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="32" height="32" rx="8" fill="#441A4A" />
      <g clip-path="url(#clip0_695_7863)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3329 5.97998C12.2273 5.9808 11.3326 6.878 11.3334 7.98358C11.3326 9.08915 12.2281 9.98635 13.3337 9.98717H15.334V7.98439C15.3349 6.87882 14.4393 5.98161 13.3329 5.97998C13.3337 5.97998 13.3337 5.97998 13.3329 5.97998ZM13.3329 11.324H8.00033C6.89476 11.3248 5.99919 12.222 6 13.3276C5.99837 14.4332 6.89394 15.3304 7.99951 15.332H13.3329C14.4385 15.3312 15.334 14.434 15.3332 13.3284C15.334 12.222 14.4385 11.3248 13.3329 11.324Z"
          fill="#36C5F0"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26 13.3276C26.0008 12.222 25.1052 11.3248 23.9996 11.324C22.8941 11.3248 21.9985 12.222 21.9993 13.3276V15.332H23.9996C25.1052 15.3312 26.0008 14.434 26 13.3276ZM20.6666 13.3276V7.98358C20.6674 6.87882 19.7726 5.98161 18.6671 5.97998C17.5615 5.9808 16.6659 6.878 16.6668 7.98358V13.3276C16.6651 14.4332 17.5607 15.3304 18.6663 15.332C19.7718 15.3312 20.6674 14.434 20.6666 13.3276Z"
          fill="#2EB67D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.6663 26.02C19.7719 26.0192 20.6674 25.122 20.6666 24.0164C20.6674 22.9108 19.7719 22.0136 18.6663 22.0128H16.666V24.0164C16.6651 25.1212 17.5607 26.0184 18.6663 26.02ZM18.6663 20.6752H23.9997C25.1052 20.6744 26.0008 19.7772 26 18.6716C26.0016 17.566 25.1061 16.6688 24.0005 16.6672H18.6671C17.5615 16.668 16.666 17.5652 16.6668 18.6708C16.666 19.7772 17.5607 20.6744 18.6663 20.6752Z"
          fill="#ECB22E"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 18.6716C5.99918 19.7772 6.89475 20.6744 8.00033 20.6752C9.1059 20.6744 10.0015 19.7772 10.0007 18.6716V16.668H8.00033C6.89475 16.6688 5.99918 17.566 6 18.6716ZM11.3334 18.6716V24.0156C11.3318 25.1212 12.2273 26.0184 13.3329 26.02C14.4385 26.0192 15.334 25.122 15.3332 24.0164V18.6732C15.3349 17.5677 14.4393 16.6705 13.3337 16.6688C12.2273 16.6688 11.3326 17.566 11.3334 18.6716Z"
          fill="#E01E5A"
        />
      </g>
      <defs>
        <clipPath id="clip0_695_7863">
          <rect
            width="20"
            height="20.04"
            fill="white"
            transform="translate(6 5.97998)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
