import { type ReactNode, useEffect, useMemo } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";

import { routes } from "constants/routes";

import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";

export type FirstTimeLoginLayoutProps = {
  children: ReactNode;
};

export const FirstTimeLoginLayout = ({
  children,
}: FirstTimeLoginLayoutProps) => {
  const { data, isLoading } = useMerchantInfo();

  const hasVerifiedPhoneNumber = useMemo(() => {
    return data?.data?.currentUser?.phoneVerified;
  }, [data]);

  useEffect(() => {
    if (hasVerifiedPhoneNumber) {
      window.location.href = routes.MANAGE.FIRST_TIME_LOGIN.PASSWORD();
    }
  }, [hasVerifiedPhoneNumber]);

  return isLoading ? (
    <LoaderBusiness />
  ) : (
    <main className="grid h-svh place-items-center">{children}</main>
  );
};
