import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ContentCopy = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Content Copy</title>
      <g id="Name=content_copy, Size=24">
        <path
          id="Vector"
          d="M4.45217 22.2321C3.99054 22.2321 3.59025 22.0626 3.25132 21.7237C2.91239 21.3847 2.74292 20.9845 2.74292 20.5228V6.35327C2.74292 6.11287 2.82518 5.91083 2.98969 5.74715C3.15421 5.58347 3.35829 5.50162 3.60192 5.50162C3.84557 5.50162 4.04819 5.58347 4.20977 5.74715C4.37137 5.91083 4.45217 6.11287 4.45217 6.35327V20.5228H15.3967C15.6371 20.5228 15.8392 20.6051 16.0028 20.7696C16.1665 20.9341 16.2484 21.1382 16.2484 21.3818C16.2484 21.6255 16.1665 21.8281 16.0028 21.9897C15.8392 22.1513 15.6371 22.2321 15.3967 22.2321H4.45217ZM7.65544 19.0228C7.19546 19.0228 6.79659 18.8539 6.45882 18.5162C6.12105 18.1784 5.95217 17.7795 5.95217 17.3196V3.42717C5.95217 2.96554 6.12105 2.56526 6.45882 2.22632C6.79659 1.88739 7.19546 1.71792 7.65544 1.71792H18.5478C19.0095 1.71792 19.4097 1.88739 19.7487 2.22632C20.0876 2.56526 20.2571 2.96554 20.2571 3.42717V17.3196C20.2571 17.7795 20.0876 18.1784 19.7487 18.5162C19.4097 18.8539 19.0095 19.0228 18.5478 19.0228H7.65544ZM7.65544 17.3196H18.5478V3.42717H7.65544V17.3196Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
