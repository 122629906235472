import { createPortal } from "react-dom";
import { Helmet } from "react-helmet";

import { Loader } from "design_system/Loader";
import { ContentLayout } from "./components/ContentLayout";
import { FooterLayout } from "./components/FooterLayout";
import { HeaderLayout } from "./components/HeaderLayout";

import { useScreenDimension } from "hooks/useScreenDimension";

import { FullPageLayoutProvider } from "./context/FullPageLayout.context";

import { cn } from "utils/classNames";
import type { FullPageLayoutProps } from "./types/FullPageLayout.types";

export const FullPageLayout = ({
  hideDividers,
  isLoading = false,
  isMobile,
  children,
  theme = "light",
  className,
}: FullPageLayoutProps) => {
  const { isMobile: isMobileDimension } = useScreenDimension();

  return createPortal(
    <FullPageLayoutProvider
      hideDividers={hideDividers ?? false}
      isMobile={isMobileDimension ?? isMobile}
      theme={theme}
    >
      <Helmet>
        <style type="text/css"></style>
      </Helmet>
      <main
        className={cn(
          "absolute top-0 flex h-screen w-screen flex-col bg-surface-1",
          {
            dark: theme === "dark",
          },
          className,
        )}
        style={{
          zIndex: 1003,
        }}
      >
        {isLoading ? <Loader variant="fullPage" /> : children}
      </main>
    </FullPageLayoutProvider>,
    document.body,
  );
};

FullPageLayout.Header = HeaderLayout;
FullPageLayout.Content = ContentLayout;
FullPageLayout.Footer = FooterLayout;
