import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const PlayArrow = ({
  size,
  fill = colorsV2.text[1],
  className,
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Play Arrow</title>
      <mask
        id="mask0_3118_4703"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_3118_4703)">
        <path
          d="M7.79688 17.2885V6.71151C7.79688 6.38434 7.91079 6.11292 8.13863 5.89725C8.36663 5.68175 8.63188 5.57401 8.93438 5.57401C9.02971 5.57401 9.13013 5.58751 9.23563 5.61451C9.34129 5.64151 9.44179 5.68392 9.53713 5.74175L17.8664 11.0365C18.0364 11.1483 18.1649 11.2903 18.2519 11.4623C18.3387 11.6341 18.3821 11.8133 18.3821 12C18.3821 12.1867 18.3387 12.3659 18.2519 12.5378C18.1649 12.7098 18.0364 12.8517 17.8664 12.9635L9.53713 18.2583C9.44179 18.3161 9.34129 18.3585 9.23563 18.3855C9.13013 18.4125 9.02971 18.426 8.93438 18.426C8.63188 18.426 8.36663 18.3183 8.13863 18.1028C7.91079 17.8871 7.79688 17.6157 7.79688 17.2885Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
