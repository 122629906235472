import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Schedule = ({
  size,
  fill = colorsV2.text[1],
  className,
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-18 w-18", className)}
      {...rest}
    >
      <title>Schedule</title>
      <g id="Name=schedule, Size=24">
        <path
          id="Vector"
          d="M12.8907 11.6615V7.51475C12.8907 7.27941 12.8137 7.08458 12.6597 6.93025C12.5057 6.77608 12.3112 6.699 12.0762 6.699C11.8412 6.699 11.6463 6.77608 11.4915 6.93025C11.3366 7.08458 11.2592 7.27941 11.2592 7.51475V11.988C11.2592 12.1 11.2779 12.2033 11.3152 12.298C11.3525 12.3928 11.4085 12.4864 11.4832 12.5787L15.0115 16.232C15.1785 16.4107 15.3821 16.4958 15.6225 16.4875C15.863 16.4792 16.0661 16.394 16.232 16.232C16.398 16.0702 16.481 15.8709 16.481 15.6342C16.481 15.3976 16.398 15.1963 16.232 15.0305L12.8907 11.6615ZM12.0007 22.1495C10.6097 22.1495 9.29713 21.8832 8.06296 21.3507C6.8288 20.8184 5.75113 20.0917 4.82996 19.1705C3.90863 18.2495 3.18171 17.172 2.64921 15.938C2.11671 14.7042 1.85046 13.3917 1.85046 12.0007C1.85046 10.6097 2.11713 9.29691 2.65046 8.06225C3.1838 6.82775 3.91046 5.7505 4.83046 4.8305C5.75046 3.9105 6.82755 3.18283 8.06171 2.6475C9.29588 2.11216 10.6086 1.8445 12 1.8445C13.3913 1.8445 14.704 2.11216 15.9382 2.6475C17.1724 3.18283 18.2495 3.9105 19.1695 4.8305C20.0895 5.7505 20.8171 6.82758 21.3525 8.06175C21.8878 9.29591 22.1555 10.6087 22.1555 12C22.1555 13.3913 21.8878 14.7041 21.3525 15.9382C20.8171 17.1724 20.0895 18.2495 19.1695 19.1695C18.2495 20.0895 17.1725 20.8162 15.9385 21.3495C14.7045 21.8828 13.3919 22.1495 12.0007 22.1495Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
