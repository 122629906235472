import type { SvgIconProps } from "types/Icon";

export const Minus = ({ size, ...rest }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Minus</title>
      <path
        d="M6.84861 11.7796H15.1454C15.3858 11.7796 15.5878 11.6974 15.7515 11.5329C15.9151 11.3683 15.997 11.1653 15.997 10.9236C15.997 10.682 15.9151 10.4804 15.7515 10.3188C15.5878 10.1572 15.3858 10.0764 15.1454 10.0764H6.84861C6.60821 10.0764 6.40618 10.1586 6.24251 10.3231C6.07883 10.4877 5.99698 10.6907 5.99698 10.9324C5.99698 11.174 6.07883 11.3756 6.24251 11.5372C6.40618 11.6988 6.60821 11.7796 6.84861 11.7796ZM11.0045 21.1525C9.60109 21.1525 8.28408 20.8868 7.05343 20.3555C5.82278 19.8242 4.74646 19.0975 3.82446 18.1755C2.90246 17.2535 2.1758 16.1769 1.64448 14.9455C1.11318 13.7142 0.847534 12.3961 0.847534 10.9913C0.847534 9.58654 1.11318 8.26821 1.64448 7.03632C2.1758 5.80443 2.90214 4.73153 3.82351 3.81765C4.74489 2.90378 5.8214 2.1803 7.05303 1.6472C8.28468 1.1141 9.60306 0.847549 11.0082 0.847549C12.4133 0.847549 13.732 1.11395 14.9644 1.64675C16.1967 2.17953 17.2697 2.9026 18.1833 3.81595C19.0969 4.7293 19.8201 5.80333 20.353 7.03802C20.886 8.27274 21.1524 9.59218 21.1524 10.9964C21.1524 12.4003 20.8859 13.7175 20.3528 14.9481C19.8197 16.1787 19.0962 17.2532 18.1823 18.1716C17.2684 19.0901 16.1945 19.8165 14.9605 20.3509C13.7265 20.8853 12.4078 21.1525 11.0045 21.1525ZM11.0095 19.4492C13.3519 19.4492 15.3431 18.6263 16.9831 16.9804C18.6232 15.3345 19.4432 13.3379 19.4432 10.9905C19.4432 8.64812 18.6247 6.6569 16.9878 5.01685C15.3509 3.37682 13.353 2.5568 10.994 2.5568C8.65793 2.5568 6.66673 3.37526 5.02036 5.01218C3.37398 6.64909 2.55078 8.64703 2.55078 11.006C2.55078 13.342 3.37372 15.3333 5.01958 16.9796C6.66545 18.626 8.66208 19.4492 11.0095 19.4492Z"
        fill="currentColor"
      />
    </svg>
  );
};
