import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const PriorityHigh = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=priority_high, Size=24">
        <path
          id="Vector"
          d="M11.9999 21.2391C11.4808 21.2391 11.0365 21.0539 10.6669 20.6834C10.2973 20.3129 10.1125 19.8675 10.1125 19.3472C10.1125 18.827 10.2984 18.3831 10.67 18.0155C11.0416 17.6479 11.487 17.4641 12.0061 17.4641C12.5252 17.4641 12.9686 17.6494 13.3362 18.0199C13.7038 18.3904 13.8875 18.8357 13.8875 19.356C13.8875 19.8763 13.7027 20.3202 13.333 20.6878C12.9634 21.0553 12.519 21.2391 11.9999 21.2391ZM12.0008 14.9641C11.497 14.9641 11.0683 14.787 10.7147 14.4327C10.3611 14.0784 10.1843 13.6503 10.1843 13.1484V4.6125C10.1843 4.11152 10.3608 3.68265 10.7139 3.3259C11.067 2.96913 11.4955 2.79075 11.9993 2.79075C12.5031 2.79075 12.9318 2.96913 13.2854 3.3259C13.639 3.68265 13.8158 4.11152 13.8158 4.6125V13.1484C13.8158 13.6503 13.6392 14.0784 13.2861 14.4327C12.933 14.787 12.5046 14.9641 12.0008 14.9641Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
