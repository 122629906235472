import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Upload = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=upload, Size=24">
        <path
          id="Vector"
          d="M5.55384 20.1494C5.09385 20.1494 4.69498 19.9806 4.35721 19.6428C4.01946 19.3051 3.85059 18.9062 3.85059 18.4462V15.7228C3.85059 15.4824 3.93284 15.2804 4.09736 15.1167C4.26188 14.953 4.46495 14.8712 4.70659 14.8712C4.94824 14.8712 5.14986 14.953 5.31146 15.1167C5.47304 15.2804 5.55384 15.4824 5.55384 15.7228V18.4462H18.4462V15.7228C18.4462 15.4824 18.5285 15.2804 18.693 15.1167C18.8575 14.953 19.0604 14.8712 19.3017 14.8712C19.543 14.8712 19.7456 14.953 19.9096 15.1167C20.0735 15.2804 20.1555 15.4824 20.1555 15.7228V18.4462C20.1555 18.9062 19.986 19.3051 19.6471 19.6428C19.3081 19.9806 18.9079 20.1494 18.4462 20.1494H5.55384ZM11.1484 7.13315L8.77504 9.50652C8.60844 9.67646 8.41135 9.75825 8.18379 9.7519C7.95622 9.74557 7.75421 9.65729 7.57776 9.48707C7.40969 9.31686 7.32683 9.11377 7.32916 8.87782C7.33148 8.64189 7.4176 8.43697 7.58754 8.26305L11.3973 4.44727C11.4893 4.35634 11.5861 4.29013 11.6877 4.24865C11.7893 4.20715 11.8947 4.1864 12.004 4.1864C12.1133 4.1864 12.2175 4.20715 12.3166 4.24865C12.4157 4.29013 12.5111 4.35634 12.6028 4.44727L16.4435 8.28805C16.6134 8.45797 16.6984 8.65787 16.6984 8.88777C16.6984 9.11766 16.6167 9.31557 16.4533 9.48152C16.2753 9.65574 16.0685 9.74178 15.8331 9.73963C15.5977 9.73749 15.395 9.65146 15.225 9.48152L12.8517 7.13315V15.2696C12.8517 15.5099 12.7694 15.712 12.6049 15.8757C12.4404 16.0394 12.2373 16.1212 11.9957 16.1212C11.754 16.1212 11.5524 16.0394 11.3908 15.8757C11.2292 15.712 11.1484 15.5099 11.1484 15.2696V7.13315Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
