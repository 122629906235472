import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

export const Contract = ({ className, ...props }: SvgIconProps) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-18 w-18", className)}
      {...props}
    >
      <title>Contract</title>
      <path
        d="M4.25986 16.6121C3.67063 16.6121 3.1698 16.4059 2.75735 15.9934C2.3449 15.581 2.13867 15.0801 2.13867 14.4909V13.0757C2.13867 12.7304 2.26193 12.4335 2.50846 12.1848C2.75497 11.936 3.05304 11.8117 3.40267 11.8117H4.39315V2.66536C4.39315 2.30977 4.51791 2.00722 4.76742 1.75771C5.01693 1.50819 5.31948 1.38342 5.67507 1.38342H14.5899C14.9411 1.38342 15.2418 1.50819 15.492 1.75771C15.7422 2.00722 15.8673 2.30977 15.8673 2.66536V14.4909C15.8673 15.0801 15.6611 15.581 15.2487 15.9934C14.8362 16.4059 14.3354 16.6121 13.7461 16.6121H4.25986ZM13.7445 15.3347C13.9831 15.3347 14.1836 15.2538 14.3461 15.0921C14.5086 14.9304 14.5899 14.73 14.5899 14.4909V2.66536H5.67507V11.8117H11.6205C11.9761 11.8117 12.2786 11.9368 12.5281 12.187C12.7776 12.4372 12.9024 12.7379 12.9024 13.0891V14.4909C12.9024 14.73 12.9831 14.9304 13.1445 15.0921C13.3059 15.2538 13.5059 15.3347 13.7445 15.3347ZM7.40294 5.99105C7.2316 5.99105 7.08829 5.93309 6.973 5.81716C6.85772 5.70125 6.80007 5.55745 6.80007 5.38576C6.80007 5.21407 6.85772 5.07034 6.973 4.95455C7.08829 4.83875 7.2316 4.78085 7.40294 4.78085H12.8576C13.0281 4.78085 13.1719 4.83984 13.2891 4.95783C13.4063 5.07582 13.4649 5.22065 13.4649 5.39234C13.4649 5.56403 13.4063 5.70674 13.2891 5.82046C13.1719 5.93419 13.0281 5.99105 12.8576 5.99105H7.40294ZM7.40294 8.33071C7.2316 8.33071 7.08829 8.27172 6.973 8.15373C6.85772 8.03574 6.80007 7.89091 6.80007 7.71924C6.80007 7.54755 6.85772 7.40409 6.973 7.28887C7.08829 7.17366 7.2316 7.11605 7.40294 7.11605H12.8576C13.0281 7.11605 13.1719 7.17504 13.2891 7.29303C13.4063 7.41102 13.4649 7.55585 13.4649 7.72754C13.4649 7.89923 13.4063 8.04268 13.2891 8.15789C13.1719 8.2731 13.0281 8.33071 12.8576 8.33071H7.40294ZM4.24111 15.3347H11.6205V13.0891H3.41611V14.4887C3.41611 14.7284 3.49736 14.9293 3.65986 15.0914C3.82236 15.2536 4.01611 15.3347 4.24111 15.3347ZM4.24111 15.3347H3.41611H11.6205H4.24111Z"
        fill="currentColor"
      />
    </svg>
  );
};
