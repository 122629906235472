import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use `Icons.ErrorOutline` instead
 */
export const ErrorOutline = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Error</title>
      <g id="Name=error_outline, Size=24">
        <path
          id="Vector"
          d="M11.9982 17.1495C12.2726 17.1495 12.5009 17.0599 12.6832 16.8809C12.8654 16.7018 12.9566 16.4751 12.9566 16.2007C12.9566 15.9263 12.866 15.697 12.685 15.5128C12.504 15.3285 12.2762 15.2364 12.0018 15.2364C11.7275 15.2364 11.4991 15.3284 11.3169 15.5123C11.1346 15.6963 11.0435 15.9255 11.0435 16.1999C11.0435 16.4742 11.134 16.7011 11.3151 16.8805C11.4961 17.0598 11.7238 17.1495 11.9982 17.1495ZM12.0794 13.181C12.3211 13.181 12.5227 13.0991 12.6843 12.9354C12.8459 12.7718 12.9267 12.5697 12.9267 12.3294V7.60598C12.9267 7.36557 12.8444 7.16354 12.6799 6.99987C12.5154 6.83619 12.3123 6.75435 12.0707 6.75435C11.829 6.75435 11.6274 6.83619 11.4658 6.99987C11.3042 7.16354 11.2234 7.36557 11.2234 7.60598V12.3294C11.2234 12.5697 11.3057 12.7718 11.4702 12.9354C11.6347 13.0991 11.8378 13.181 12.0794 13.181ZM12.0075 22.1495C10.6041 22.1495 9.28714 21.8838 8.05649 21.3525C6.82584 20.8212 5.74951 20.0945 4.82751 19.1725C3.90551 18.2505 3.17885 17.1739 2.64754 15.9425C2.11624 14.7112 1.85059 13.3931 1.85059 11.9883C1.85059 10.5835 2.11624 9.26521 2.64754 8.03332C3.17885 6.80142 3.90519 5.72853 4.82656 4.81465C5.74794 3.90078 6.82445 3.1773 8.05609 2.6442C9.28774 2.1111 10.6061 1.84455 12.0112 1.84455C13.4163 1.84455 14.7351 2.11095 15.9674 2.64375C17.1998 3.17653 18.2728 3.8996 19.1863 4.81295C20.0999 5.7263 20.8232 6.80032 21.3561 8.03502C21.889 9.26974 22.1555 10.5892 22.1555 11.9934C22.1555 13.3973 21.8889 14.7145 21.3558 15.9451C20.8227 17.1757 20.0993 18.2502 19.1854 19.1686C18.2715 20.0871 17.1976 20.8135 15.9636 21.3479C14.7296 21.8823 13.4109 22.1495 12.0075 22.1495ZM12.0125 20.4462C14.3549 20.4462 16.3461 19.6233 17.9862 17.9774C19.6262 16.3315 20.4462 14.3349 20.4462 11.9875C20.4462 9.64512 19.6278 7.6539 17.9909 6.01385C16.3539 4.37382 14.356 3.5538 11.997 3.5538C9.66099 3.5538 7.66978 4.37226 6.02341 6.00917C4.37703 7.64609 3.55384 9.64403 3.55384 12.003C3.55384 14.339 4.37677 16.3303 6.02264 17.9766C7.6685 19.623 9.66514 20.4462 12.0125 20.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
