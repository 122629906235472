import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const VariableAdd = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1708_8700)">
        <path
          d="M3.70221 17.1494C3.46181 17.1494 3.25977 17.0676 3.09609 16.9039C2.93242 16.7403 2.85059 16.5382 2.85059 16.2978V7.70217C2.85059 7.46227 2.93242 7.25936 3.09609 7.09344C3.25977 6.92751 3.46181 6.84454 3.70221 6.84454H20.2979C20.5378 6.84454 20.7407 6.92751 20.9066 7.09344C21.0725 7.25936 21.1555 7.46227 21.1555 7.70217V10.238C21.1555 10.4784 21.0721 10.6805 20.9052 10.8441C20.7383 11.0078 20.5342 11.0897 20.293 11.0897C20.0596 11.0897 19.8602 11.0158 19.6946 10.8681C19.529 10.7203 19.4462 10.5322 19.4462 10.3038V8.55379H4.55384V15.4462H14.2381C14.4785 15.4462 14.6805 15.5285 14.8442 15.693C15.0079 15.8575 15.0897 16.0606 15.0897 16.3022C15.0897 16.5439 15.0079 16.7455 14.8442 16.9071C14.6805 17.0687 14.4785 17.1494 14.2381 17.1494H3.70221ZM19.5299 17.0717H17.3636C17.1444 17.0717 16.9605 16.9974 16.8122 16.8488C16.6639 16.7002 16.5897 16.5161 16.5897 16.2964C16.5897 16.0767 16.6639 15.893 16.8122 15.7454C16.9605 15.5977 17.1444 15.5239 17.3636 15.5239H19.5299V13.3636C19.5299 13.1443 19.6039 12.9605 19.7518 12.8122C19.8997 12.6638 20.0831 12.5897 20.3017 12.5897C20.5221 12.5897 20.7055 12.6638 20.852 12.8122C20.9985 12.9605 21.0718 13.1443 21.0718 13.3636V15.5239H23.2381C23.4551 15.5239 23.6383 15.5986 23.7878 15.748C23.9373 15.8974 24.012 16.0826 24.012 16.3035C24.012 16.5243 23.9373 16.7076 23.7878 16.8532C23.6383 16.9989 23.4551 17.0717 23.2381 17.0717H21.0718V19.238C21.0718 19.4556 20.9975 19.638 20.8489 19.7852C20.7002 19.9324 20.5161 20.006 20.2964 20.006C20.0767 20.006 19.8941 19.9324 19.7484 19.7852C19.6028 19.638 19.5299 19.4556 19.5299 19.238V17.0717Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1708_8700">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
