import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Add = ({ size, fill, className, ...rest }: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-24 w-24 text-text-color-01", className)}
      {...rest}
    >
      <title>Add</title>
      <g id="Name=add, Size=24">
        <path
          id="Vector"
          d="M11.1484 12.8516H5.70221C5.46181 12.8516 5.25977 12.7694 5.09609 12.6048C4.93242 12.4403 4.85059 12.2373 4.85059 11.9956C4.85059 11.754 4.93242 11.5524 5.09609 11.3907C5.25977 11.2292 5.46181 11.1484 5.70221 11.1484H11.1484V5.70217C11.1484 5.46227 11.2307 5.25937 11.3952 5.09345C11.5597 4.92752 11.7628 4.84455 12.0044 4.84455C12.2461 4.84455 12.4477 4.92752 12.6093 5.09345C12.7709 5.25937 12.8517 5.46227 12.8517 5.70217V11.1484H18.2979C18.5378 11.1484 18.7407 11.2306 18.9066 11.3951C19.0725 11.5597 19.1555 11.7627 19.1555 12.0044C19.1555 12.246 19.0725 12.4476 18.9066 12.6092C18.7407 12.7708 18.5378 12.8516 18.2979 12.8516H12.8517V18.2978C12.8517 18.5382 12.7694 18.7403 12.6049 18.904C12.4404 19.0676 12.2373 19.1495 11.9957 19.1495C11.754 19.1495 11.5524 19.0676 11.3908 18.904C11.2292 18.7403 11.1484 18.5382 11.1484 18.2978V12.8516Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
