import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Gavel = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Gavel</title>
      <g clip-path="url(#clip0_4081_23509)">
        <mask
          id="mask0_4081_23509"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect width="18" height="18" fill="currentColor" />
        </mask>
        <g mask="url(#mask0_4081_23509)">
          <path
            d="M3.42812 14.7778H11.4062C11.5746 14.7778 11.7149 14.8339 11.8272 14.9461C11.9394 15.0584 11.9956 15.1988 11.9956 15.3673C11.9956 15.5356 11.9394 15.6759 11.8272 15.7882C11.7149 15.9004 11.5746 15.9566 11.4062 15.9566H3.42362C3.25512 15.9566 3.1155 15.9004 3.00475 15.7882C2.894 15.6759 2.83862 15.5356 2.83862 15.3673C2.83862 15.1988 2.89475 15.0584 3.007 14.9461C3.11925 14.8339 3.25962 14.7778 3.42812 14.7778ZM6.19562 11.0864L3.71575 8.60656C3.47337 8.36419 3.34906 8.06894 3.34281 7.72081C3.33656 7.37269 3.45612 7.07119 3.7015 6.81632L4.24075 6.25382L8.54837 10.5239L7.98587 11.0864C7.7435 11.3288 7.44587 11.45 7.093 11.45C6.74025 11.45 6.44112 11.3288 6.19562 11.0864ZM11.9969 7.07113L7.72675 2.7635L8.28925 2.21975C8.54412 1.98038 8.84487 1.86306 9.1915 1.86781C9.538 1.87256 9.834 1.99613 10.0795 2.2385L12.5594 4.71838C12.8047 4.96375 12.9274 5.26288 12.9274 5.61575C12.9274 5.96863 12.8047 6.26625 12.5594 6.50863L11.9969 7.07113ZM15.265 14.6872L5.55494 4.97713L6.45006 4.07769L16.1601 13.7923C16.2876 13.9196 16.3514 14.0688 16.3514 14.2396C16.3514 14.4106 16.2876 14.5598 16.1601 14.6872C16.0326 14.8147 15.8834 14.8784 15.7126 14.8784C15.5417 14.8784 15.3925 14.8147 15.265 14.6872Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4081_23509">
          <rect width="18" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
