export enum ProcessingFeeTypes {
  FIXED = "Fixed amount",
  PERCENTAGE = "Percentage",
}

export type TPaymentLinkCreateTabs =
  | "details"
  | "customizations"
  | "advanced-settings";

export type TCurrentStepMobile =
  | "add-title-description"
  | "set-amount"
  | "customize";

export interface CreateLinkData {
  capacity: number | null;
  currency: string;
  customerDetails: boolean;
  description: string | null;
  expirationDate: string | null;
  expirationPeriod: string | null;
  includeCapacity: boolean;
  includeChargeCardLater: boolean;
  includeExpirationDate: boolean;
  includeFee: boolean;
  includeInternalNote: boolean;
  includeQrCode: boolean;
  includeQuantity: boolean;
  includeRedirectUrl: boolean;
  includeTabbyBNPL: boolean;
  includeTerms: boolean;
  includeTips: boolean;
  internalNote: string | null;
  invoiceDate: string;
  invoiceNumber: string;
  linkType: string | null;
  message: boolean;
  prefilledAmount: number | null;
  processingFeeAmount: number | null;
  processingFeePercentage: number | null;
  processingFeeType: ProcessingFeeTypes;
  returnUrl: string | null;
  tabbyAvailability: boolean;
  tabbyTermAgreement: boolean;
  termsConditionsUrl: string | null;
  title: string | null;
}

export interface CreateLinkFieldConfigurations {
  displayDescription: boolean;
  displayInvoiceDate: boolean;
  displayInvoiceNumber: boolean;
  displayTips: boolean;
}

export interface LinkFieldConfiguration {
  description: "display" | "hidden";
  invoiceDate: "display" | "hidden";
  invoiceNumber: "display" | "hidden";
  tips: "display" | "hidden";
}

export interface IDefaultLinkSettingResponse {
  fieldConfigurations: LinkFieldConfiguration;
  linkDefaultSetting: {
    capacity: number | null;
    currency: string | null;
    description: string | null;
    enableCustomerDetails: boolean | null;
    enableMessage: boolean | null;
    enableQrCode: boolean | null;
    enableQuantity: boolean | null;
    enableTabby: boolean | null;
    enableTips: boolean | null;
    expirationPeriod: string | null;
    prefilledAmount: number | null;
    processingFeeAmount: number | null;
    processingFeePercentage: number | null;
    returnUrl: string | null;
    termsConditionsUrl: string | null;
    title: string | null;
    holdAndChargeLaterPeriod: number | null;
  };
  tabbyAvailability: boolean;
  tabbyTermAgreement: boolean;
}

export interface CreateLinkState {
  currentStepMobile: TCurrentStepMobile;
  currentTab: TPaymentLinkCreateTabs;
  data: CreateLinkData;
  hasErrorInForm: boolean;
  fieldConfigurations: CreateLinkFieldConfigurations;
  isSubmitDisabled: boolean;
  loading: boolean;
  premiumAccess: boolean;
  requiredFields: string[];
  trialAccess: boolean;
  holdAndChargeLaterPeriod: number;
}

export interface UpdateDataPayload {
  data: Partial<CreateLinkData>;
}
