import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Info = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Info</title>
      <path
        d="M12.0782 17.0657C12.3102 17.0657 12.5036 16.9876 12.6585 16.8312C12.8134 16.6749 12.8908 16.4811 12.8908 16.25V11.8157C12.8908 11.5846 12.8123 11.3909 12.6554 11.2345C12.4984 11.0782 12.3039 11 12.0719 11C11.8399 11 11.6465 11.0782 11.4916 11.2345C11.3367 11.3909 11.2593 11.5846 11.2593 11.8157V16.25C11.2593 16.4811 11.3378 16.6749 11.4947 16.8312C11.6517 16.9876 11.8461 17.0657 12.0782 17.0657ZM12.0001 9.23967C12.2593 9.23967 12.4765 9.15441 12.6518 8.98389C12.8271 8.81336 12.9147 8.60206 12.9147 8.34999C12.9147 8.07926 12.8271 7.85497 12.6519 7.67712C12.4766 7.49925 12.2595 7.41032 12.0005 7.41032C11.737 7.41032 11.5186 7.49925 11.3453 7.67712C11.172 7.85497 11.0854 8.07826 11.0854 8.34699C11.0854 8.60156 11.173 8.81398 11.3484 8.98427C11.5237 9.15453 11.7409 9.23967 12.0001 9.23967ZM12.0075 22.1494C10.6041 22.1494 9.28714 21.8838 8.05649 21.3525C6.82584 20.8212 5.74951 20.0945 4.82751 19.1725C3.90551 18.2505 3.17885 17.1738 2.64754 15.9425C2.11624 14.7112 1.85059 13.3931 1.85059 11.9883C1.85059 10.5835 2.11624 9.2652 2.64754 8.03332C3.17885 6.80142 3.90519 5.72853 4.82656 4.81464C5.74794 3.90078 6.82445 3.17729 8.05609 2.64419C9.28774 2.11109 10.6061 1.84454 12.0112 1.84454C13.4163 1.84454 14.7351 2.11094 15.9674 2.64374C17.1998 3.17653 18.2728 3.89959 19.1863 4.81294C20.0999 5.72629 20.8232 6.80032 21.3561 8.03502C21.889 9.26974 22.1555 10.5892 22.1555 11.9933C22.1555 13.3973 21.8889 14.7145 21.3558 15.9451C20.8227 17.1757 20.0993 18.2502 19.1854 19.1686C18.2715 20.0871 17.1976 20.8135 15.9636 21.3479C14.7296 21.8823 13.4109 22.1494 12.0075 22.1494Z"
        fill={fill}
      />
    </svg>
  );
};
