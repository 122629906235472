import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ArrowRightAlt = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=arrow_right_alt, Size=24">
        <path
          id="Vector"
          d="M16.8919 12.8016H4.70221C4.45764 12.8016 4.25456 12.7208 4.09296 12.5593C3.93138 12.3977 3.85059 12.1946 3.85059 11.95C3.85059 11.7054 3.93138 11.5024 4.09296 11.3408C4.25456 11.1792 4.45764 11.0984 4.70221 11.0984H16.8919L13.3593 7.55978C13.1894 7.39385 13.1086 7.19593 13.1169 6.96603C13.1252 6.73615 13.2144 6.53624 13.3843 6.36631C13.5502 6.20037 13.7471 6.1184 13.975 6.12038C14.2029 6.12238 14.4019 6.20635 14.5718 6.37228L19.5528 11.3473C19.6441 11.4386 19.7104 11.5339 19.7517 11.6332C19.793 11.7324 19.8136 11.8381 19.8136 11.95C19.8136 12.062 19.793 12.1676 19.7517 12.2669C19.7104 12.3661 19.6441 12.4614 19.5528 12.5527L14.5968 17.5027C14.4269 17.6687 14.2279 17.7526 14 17.7546C13.7721 17.7566 13.5752 17.6726 13.4093 17.5027C13.2394 17.3368 13.1544 17.1347 13.1544 16.8965C13.1544 16.6582 13.2394 16.4542 13.4093 16.2843L16.8919 12.8016Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
