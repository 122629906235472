import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const OpenInNew = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Open in new</title>
      <path
        d="M4.55078 21.1525C4.0908 21.1525 3.69193 20.9836 3.35416 20.6458C3.01641 20.3081 2.84753 19.9092 2.84753 19.4492V4.5568C2.84753 4.09518 3.01641 3.69491 3.35416 3.35598C3.69193 3.01703 4.0908 2.84755 4.55078 2.84755H10.6204C10.8608 2.84755 11.0628 2.93098 11.2265 3.09785C11.3901 3.26472 11.472 3.46879 11.472 3.71008C11.472 3.95138 11.3901 4.15282 11.2265 4.31442C11.0628 4.47601 10.8608 4.5568 10.6204 4.5568H4.55078V19.4492H19.4432V13.3796C19.4432 13.1392 19.5254 12.9372 19.69 12.7735C19.8545 12.6098 20.0574 12.528 20.2987 12.528C20.54 12.528 20.7426 12.6098 20.9065 12.7735C21.0705 12.9372 21.1524 13.1392 21.1524 13.3796V19.4492C21.1524 19.9092 20.983 20.3081 20.644 20.6458C20.3051 20.9836 19.9048 21.1525 19.4432 21.1525H4.55078ZM19.4432 5.7693L10.2812 14.9373C10.1082 15.1072 9.90951 15.187 9.68516 15.1767C9.46079 15.1663 9.26365 15.0762 9.09373 14.9062C8.9238 14.7363 8.83883 14.5384 8.83883 14.3125C8.83883 14.0866 8.9238 13.8887 9.09373 13.7188L18.2557 4.5568H13.8236C13.5832 4.5568 13.3812 4.47454 13.2175 4.31003C13.0538 4.14551 12.972 3.9426 12.972 3.7013C12.972 3.46002 13.0538 3.25741 13.2175 3.09348C13.3812 2.92953 13.5832 2.84755 13.8236 2.84755H21.1524V10.1764C21.1524 10.4168 21.069 10.6188 20.9021 10.7825C20.7353 10.9462 20.5312 11.028 20.2899 11.028C20.0486 11.028 19.8472 10.9462 19.6856 10.7825C19.524 10.6188 19.4432 10.4168 19.4432 10.1764V5.7693Z"
        fill="currentColor"
      />
    </svg>
  );
};
