import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ExpandMore = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Expand more</title>
      <g id="Name=expand_more, Size=24">
        <path
          id="Vector"
          d="M12 15.1706C11.888 15.1706 11.7824 15.15 11.6831 15.1087C11.5839 15.0674 11.4886 15.0011 11.3973 14.9098L6.45324 9.96575C6.28332 9.79583 6.19836 9.59692 6.19836 9.36902C6.19836 9.14112 6.28332 8.94221 6.45324 8.77227C6.62317 8.60236 6.82626 8.5174 7.06249 8.5174C7.29872 8.5174 7.50181 8.60236 7.67174 8.77227L12 13.1005L16.3282 8.77227C16.4982 8.60236 16.6971 8.5174 16.925 8.5174C17.1529 8.5174 17.3518 8.60236 17.5217 8.77227C17.6917 8.94221 17.7766 9.14529 17.7766 9.38152C17.7766 9.61776 17.6917 9.82083 17.5217 9.99075L12.6027 14.9098C12.5114 15.0011 12.4161 15.0674 12.3168 15.1087C12.2176 15.15 12.1119 15.1706 12 15.1706Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
