import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

export const AllInclusive = ({ className, ...rest }: SvgIconProps) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-16 w-16", className)}
      {...rest}
    >
      <title>All Inclusive</title>
      <g clipPath="url(#clip0_367_12179)">
        <path
          d="M3.97283 13.2013C2.82833 13.2013 1.86733 12.7845 1.08983 11.9507C0.312334 11.117 -0.076416 10.1209 -0.076416 8.96252C-0.076416 7.81664 0.315459 6.83621 1.09921 6.02121C1.88296 5.20621 2.84083 4.79871 3.97283 4.79871C4.43183 4.79871 4.87533 4.87521 5.30333 5.02821C5.73133 5.18108 6.11121 5.41683 6.44296 5.73546L8.1464 7.37814L7.24227 8.26877L5.63333 6.69621C5.41446 6.47721 5.16158 6.31914 4.87471 6.22202C4.58783 6.12489 4.28796 6.07633 3.97508 6.07633C3.19446 6.07633 2.53702 6.35927 2.00277 6.92514C1.4684 7.49102 1.20121 8.17071 1.20121 8.96421C1.20121 9.77021 1.46521 10.465 1.99321 11.0485C2.52133 11.632 3.1819 11.9237 3.9749 11.9237C4.2784 11.9237 4.5729 11.8752 4.8584 11.7782C5.14377 11.6811 5.40158 11.5293 5.63183 11.323L11.5568 5.73546C11.8886 5.41683 12.2685 5.18108 12.6965 5.02821C13.1245 4.87521 13.5617 4.79871 14.0082 4.79871C15.1527 4.79871 16.1168 5.20621 16.9006 6.02121C17.6843 6.83621 18.0762 7.81664 18.0762 8.96252C18.0762 10.1209 17.6843 11.117 16.9006 11.9507C16.1168 12.7845 15.1527 13.2013 14.0082 13.2013C13.5617 13.2013 13.1213 13.128 12.6871 12.9812C12.2528 12.8346 11.8761 12.602 11.5568 12.2833L9.8954 10.6406L10.795 9.75002L12.3678 11.323C12.5744 11.5295 12.8232 11.6812 13.1142 11.7782C13.4053 11.8752 13.7026 11.9237 14.0061 11.9237C14.7991 11.9237 15.4628 11.6317 15.9972 11.0477C16.5315 10.4637 16.7986 9.76914 16.7986 8.96402C16.7986 8.17139 16.5283 7.49196 15.9878 6.92571C15.4472 6.35946 14.7866 6.07633 14.0061 6.07633C13.7026 6.07633 13.4081 6.13108 13.1226 6.24058C12.8373 6.35021 12.5857 6.50821 12.368 6.71458L6.44296 12.3021C6.11121 12.6082 5.72821 12.8346 5.29396 12.9812C4.85971 13.128 4.41933 13.2013 3.97283 13.2013Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_367_12179">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
