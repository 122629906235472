import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Favorite = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      {...rest}
    >
      <path
        d="M479.769-134.739q-12.381 0-24.778-4.347-12.396-4.348-21.999-14.055l-52.97-49.229q-123.761-114.282-214.881-219.5Q74.021-527.087 74.021-643q0-92.811 62.037-155.014 62.035-62.204 153.703-62.204 50.761 0 100.522 23.783 49.76 23.783 89.478 78.826 44.717-55.043 91.478-78.826 46.761-23.783 98.522-23.783 91.961 0 154.209 62.204Q886.218-735.811 886.218-643q0 115.913-91.479 221.13-91.478 105.218-215 219.74L526.5-152.891q-9.446 9.576-21.747 13.864t-24.984 4.288Zm-27.204-547.304q-26.043-48.044-70.282-79.044-44.24-31-92.458-31-65.003 0-106.338 41.868-41.335 41.868-41.335 107.648 0 57.201 38.572 120.438 38.572 63.238 92.26 122.692 53.688 59.454 111.397 110.769 57.71 51.315 95.426 86.042 37.807-34 95.45-85.794 57.643-51.793 111.356-111.782 53.713-59.99 92.593-123.148 38.881-63.158 38.881-119.697 0-65.232-41.989-107.134-41.988-41.902-106.221-41.902-49.399 0-93.138 30.5-43.739 30.5-71.543 79.544-5.071 8.478-11.634 12.217-6.562 3.739-14.921 3.739-8.358 0-15.124-3.739t-10.952-12.217ZM480-497.478Z"
        fill={fill}
      />
    </svg>
  );
};
