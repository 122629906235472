import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ArrowBack = ({
  size,
  fill = colorsV2.text[1],
  className,
  ...rest
}: SvgIconProps) => {
  if (process.env.NODE_ENV === "development" && fill !== colorsV2.text[1]) {
    console.warn(
      'ArrowBack: "fill" prop is deprecated. Please use "className" for styling instead.',
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={fill !== colorsV2.text[1] ? { color: fill } : undefined}
      {...rest}
    >
      <title>Arrow Back</title>
      <g id="Name=arrow_back, Size=24">
        <path
          id="Vector"
          d="M7.09513 12.8516L12.5967 18.3533C12.7667 18.5236 12.8526 18.7236 12.8546 18.9533C12.8566 19.183 12.7726 19.3837 12.6027 19.5553C12.4328 19.7275 12.2322 19.8126 12.0011 19.8106C11.7699 19.8086 11.5687 19.7226 11.3973 19.5527L4.44728 12.6027C4.35634 12.5108 4.29013 12.4151 4.24865 12.3157C4.20715 12.2163 4.1864 12.1108 4.1864 11.9993C4.1864 11.8878 4.20715 11.7825 4.24865 11.6835C4.29013 11.5844 4.35634 11.489 4.44728 11.3973L11.4033 4.4413C11.5792 4.27137 11.7806 4.1864 12.0075 4.1864C12.2344 4.1864 12.4328 4.27137 12.6027 4.4413C12.7726 4.61522 12.8576 4.81599 12.8576 5.04363C12.8576 5.27126 12.7726 5.4703 12.6027 5.64075L7.09513 11.1484H19.2978C19.5419 11.1484 19.7458 11.2292 19.9097 11.3908C20.0735 11.5524 20.1554 11.7554 20.1554 12C20.1554 12.2446 20.0735 12.4477 19.9097 12.6093C19.7458 12.7708 19.5419 12.8516 19.2978 12.8516H7.09513Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
