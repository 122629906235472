import type { SvgIconProps } from "types/Icon";

type VisaCardBoldBlackProps = Omit<SvgIconProps, "fill"> & {
  width?: number;
  height?: number;
};

/**
 * @deprecated Use from '@_icons' instead
 */
export const VisaCardBoldBlack = ({
  width = 36,
  height = 56,
}: VisaCardBoldBlackProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.445711"
        y="0.0271318"
        width="35.1085"
        height="55.9457"
        rx="1.60078"
        fill="#222223"
      />
      <rect
        x="0.445711"
        y="0.0271318"
        width="35.1085"
        height="55.9457"
        rx="1.60078"
        fill="url(#paint0_linear_3072_10414)"
        fill-opacity="0.15"
      />
      <rect
        x="0.445711"
        y="0.0271318"
        width="35.1085"
        height="55.9457"
        rx="1.60078"
        fill="url(#paint1_linear_3072_10414)"
      />
      <rect
        x="0.445711"
        y="0.0271318"
        width="35.1085"
        height="55.9457"
        rx="1.60078"
        stroke="url(#paint2_linear_3072_10414)"
        stroke-width="0.0542636"
      />
      <g filter="url(#filter0_i_3072_10414)">
        <mask
          id="mask0_3072_10414"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="22"
          y="49"
          width="12"
          height="5"
        >
          <path
            d="M33.4093 49.8433H22.1198V53.4891H33.4093V49.8433Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3072_10414)">
          <path
            d="M26.3997 49.9039L24.9213 53.4303H23.9571L23.2296 50.6164C23.1848 50.4436 23.1464 50.3796 23.012 50.3071C22.7923 50.1876 22.4318 50.0767 22.1118 50.0063L22.1331 49.9039H23.6861C23.8845 49.9039 24.0616 50.0362 24.1064 50.2644L24.4904 52.306L25.4397 49.906H26.3976L26.3997 49.9039ZM30.1778 52.2804C30.1821 51.3503 28.8914 51.2991 28.9 50.8831C28.9021 50.7572 29.0237 50.6228 29.2861 50.5887C29.4162 50.5716 29.7768 50.5588 30.1864 50.7466L30.3463 49.9978C30.1266 49.9188 29.845 49.842 29.493 49.842C28.5906 49.842 27.957 50.322 27.9506 51.0068C27.9442 51.5146 28.4029 51.7983 28.7485 51.9668C29.1048 52.1396 29.2242 52.2506 29.2221 52.4042C29.22 52.641 28.9384 52.7455 28.676 52.7498C28.2173 52.7562 27.9506 52.626 27.7394 52.5279L27.573 53.3001C27.7864 53.3983 28.1789 53.4836 28.5885 53.4879C29.5464 53.4879 30.1736 53.0143 30.1757 52.2804M32.5586 53.4303H33.4034L32.6674 49.9039H31.8887C31.7138 49.9039 31.5666 50.0063 31.5005 50.162L30.1309 53.4303H31.0887L31.2786 52.9034H32.4498L32.5607 53.4303H32.5586ZM31.541 52.1823L32.021 50.8575L32.2983 52.1823H31.541ZM27.7032 49.9039L26.948 53.4303H26.0349L26.7901 49.9039H27.701H27.7032Z"
            fill="url(#paint3_linear_3072_10414)"
          />
          <g
            style={{
              mixBlendMode: "color",
            }}
          >
            <path
              d="M26.3997 49.9039L24.9213 53.4303H23.9571L23.2296 50.6164C23.1848 50.4436 23.1464 50.3796 23.012 50.3071C22.7923 50.1876 22.4318 50.0767 22.1118 50.0063L22.1331 49.9039H23.6861C23.8845 49.9039 24.0616 50.0362 24.1064 50.2644L24.4904 52.306L25.4397 49.906H26.3976L26.3997 49.9039ZM30.1778 52.2804C30.1821 51.3503 28.8914 51.2991 28.9 50.8831C28.9021 50.7572 29.0237 50.6228 29.2861 50.5887C29.4162 50.5716 29.7768 50.5588 30.1864 50.7466L30.3463 49.9978C30.1266 49.9188 29.845 49.842 29.493 49.842C28.5906 49.842 27.957 50.322 27.9506 51.0068C27.9442 51.5146 28.4029 51.7983 28.7485 51.9668C29.1048 52.1396 29.2242 52.2506 29.2221 52.4042C29.22 52.641 28.9384 52.7455 28.676 52.7498C28.2173 52.7562 27.9506 52.626 27.7394 52.5279L27.573 53.3001C27.7864 53.3983 28.1789 53.4836 28.5885 53.4879C29.5464 53.4879 30.1736 53.0143 30.1757 52.2804M32.5586 53.4303H33.4034L32.6674 49.9039H31.8887C31.7138 49.9039 31.5666 50.0063 31.5005 50.162L30.1309 53.4303H31.0887L31.2786 52.9034H32.4498L32.5607 53.4303H32.5586ZM31.541 52.1823L32.021 50.8575L32.2983 52.1823H31.541ZM27.7032 49.9039L26.948 53.4303H26.0349L26.7901 49.9039H27.701H27.7032Z"
              fill="#CDD3E9"
            />
          </g>
        </g>
      </g>
      <g filter="url(#filter1_i_3072_10414)">
        <rect
          x="15.5514"
          y="3.67786"
          width="5.10416"
          height="5.83333"
          rx="0.692708"
          fill="url(#paint4_linear_3072_10414)"
        />
      </g>
      <rect
        x="15.5696"
        y="3.69609"
        width="5.0677"
        height="5.79688"
        rx="0.674479"
        stroke="#D5D9E2"
        stroke-width="0.0364583"
      />
      <rect
        x="17.3563"
        y="3.73112"
        width="1.42188"
        height="2.29688"
        rx="0.273438"
        stroke="url(#paint5_linear_3072_10414)"
        stroke-width="0.0364583"
      />
      <rect
        x="17.3563"
        y="7.23338"
        width="1.42188"
        height="2.26042"
        rx="0.273438"
        stroke="url(#paint6_linear_3072_10414)"
        stroke-width="0.0364583"
      />
      <rect
        x="15.568"
        y="7.19692"
        width="1.20313"
        height="5.0677"
        rx="0.273438"
        transform="rotate(-90 15.568 7.19692)"
        stroke="url(#paint7_linear_3072_10414)"
        stroke-width="0.0364583"
      />
      <rect
        x="16.9588"
        y="7.30452"
        width="1.42188"
        height="2.22396"
        rx="0.273438"
        transform="rotate(-90 16.9588 7.30452)"
        fill="url(#paint8_linear_3072_10414)"
        stroke="url(#paint9_linear_3072_10414)"
        stroke-width="0.0364583"
      />
      <path
        d="M18.1219 18.4056C15.493 18.4056 13.0441 19.4511 11.223 21.3484C9.39963 23.248 8.45792 25.7477 8.56871 28.3858C8.77644 33.3482 12.8225 37.3689 17.7803 37.5421C17.8958 37.5467 18.0112 37.549 18.1243 37.549C20.6239 37.549 22.9759 36.6004 24.7785 34.86C26.6619 33.039 27.7006 30.5947 27.7006 27.9796C27.7006 22.7033 23.4029 18.4079 18.1196 18.4079L18.1219 18.4056ZM25.4871 28.3604C25.2978 32.0834 22.235 35.1462 18.512 35.3355C16.4301 35.4394 14.3897 34.6569 12.9195 33.1867C11.4492 31.7164 10.6668 29.6784 10.7706 27.5941C10.9599 23.8712 14.0228 20.8083 17.7457 20.6191C19.8299 20.5129 21.868 21.2976 23.3383 22.7679C24.8085 24.2382 25.591 26.2762 25.4871 28.3604Z"
        fill="url(#paint10_linear_3072_10414)"
      />
      <g
        style={{
          mixBlendMode: "color",
        }}
      >
        <path
          d="M18.1219 18.4056C15.493 18.4056 13.0441 19.4511 11.223 21.3484C9.39963 23.248 8.45792 25.7477 8.56871 28.3858C8.77644 33.3482 12.8225 37.369 17.7803 37.5421C17.8958 37.5467 18.0112 37.549 18.1243 37.549C20.6239 37.549 22.9759 36.6004 24.7785 34.86C26.6619 33.039 27.7006 30.5947 27.7006 27.9796C27.7006 22.7033 23.4029 18.4079 18.1196 18.4079L18.1219 18.4056ZM25.4871 28.3604C25.2978 32.0834 22.235 35.1462 18.512 35.3355C16.4301 35.4394 14.3897 34.6569 12.9195 33.1867C11.4492 31.7164 10.6668 29.6784 10.7706 27.5941C10.9599 23.8712 14.0228 20.8083 17.7457 20.6191C19.8299 20.5129 21.868 21.2976 23.3383 22.7679C24.8085 24.2382 25.591 26.2762 25.4871 28.3604Z"
          fill="url(#paint11_linear_3072_10414)"
        />
      </g>
      <path
        d="M18.1282 33.6971C21.2164 33.6971 23.7369 31.2205 23.8084 28.1508H12.4479C12.5195 31.2228 15.0399 33.6971 18.1282 33.6971Z"
        fill="url(#paint12_linear_3072_10414)"
      />
      <g
        style={{
          mixBlendMode: "color",
        }}
      >
        <path
          d="M18.1282 33.6971C21.2164 33.6971 23.7369 31.2205 23.8084 28.1508H12.4479C12.5195 31.2228 15.0399 33.6971 18.1282 33.6971Z"
          fill="url(#paint13_linear_3072_10414)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_3072_10414"
          x="20.9147"
          y="48.4487"
          width="13.6719"
          height="6.38019"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.109375" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3072_10414"
          />
        </filter>
        <filter
          id="filter1_i_3072_10414"
          x="15.5514"
          y="3.67786"
          width="5.10419"
          height="5.97915"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.145833" />
          <feGaussianBlur stdDeviation="0.0729167" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_3072_10414"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3072_10414"
          x1="56.4806"
          y1="24.9156"
          x2="17.869"
          y2="9.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.150537" stop-opacity="0" />
          <stop
            offset="0.489452"
            stop-color="#88C9BC"
            stop-opacity="0.616103"
          />
          <stop offset="0.579611" stop-color="#BDACFF" stop-opacity="0.78" />
          <stop offset="0.930898" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3072_10414"
          x1="34.1843"
          y1="-6.97156e-07"
          x2="1.69952"
          y2="54.7911"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.696782" stop-color="#222223" stop-opacity="0" />
          <stop offset="1" stop-color="#485CC7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3072_10414"
          x1="18"
          y1="56"
          x2="18"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#999EFF" />
          <stop offset="1" stop-color="white" stop-opacity="0.4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3072_10414"
          x1="20.1853"
          y1="49.8718"
          x2="34.1301"
          y2="56.5963"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.23" stop-color="#FAFBFB" />
          <stop offset="0.39" stop-color="#8AA6E8" />
          <stop offset="0.63" stop-color="#EDF2FF" stop-opacity="0.87" />
          <stop offset="0.985" stop-color="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3072_10414"
          x1="19.0149"
          y1="3.67786"
          x2="15.5514"
          y2="9.51119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3072_10414"
          x1="18.0672"
          y1="6.04623"
          x2="18.0672"
          y2="3.71289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B1BBC8" />
          <stop offset="1" stop-color="#D5D9E2" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3072_10414"
          x1="18.0672"
          y1="7.21515"
          x2="18.0672"
          y2="9.51202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B1BBC8" />
          <stop offset="1" stop-color="#D5D9E2" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3072_10414"
          x1="16.1696"
          y1="12.3193"
          x2="16.1696"
          y2="7.21515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D5D9E2" />
          <stop offset="0.478596" stop-color="#B1BBC8" />
          <stop offset="1" stop-color="#D5D9E2" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3072_10414"
          x1="17.6698"
          y1="9.58317"
          x2="16.0109"
          y2="7.19515"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F6F6F6" />
          <stop offset="1" stop-color="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3072_10414"
          x1="17.6698"
          y1="7.32275"
          x2="17.6698"
          y2="9.58317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B1BBC8" />
          <stop offset="1" stop-color="#D5D9E2" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3072_10414"
          x1="-8.37253"
          y1="18.7292"
          x2="50.8527"
          y2="39.3229"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.264853" stop-color="#DAE5FF" />
          <stop offset="0.374574" stop-color="#EDFFF4" />
          <stop offset="0.482763" stop-color="#DAE5FF" />
          <stop offset="0.565541" stop-color="#E6E1F1" />
          <stop offset="0.626097" stop-color="#FFE1E5" />
          <stop offset="0.669334" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3072_10414"
          x1="-8.37253"
          y1="18.7292"
          x2="50.8527"
          y2="39.3229"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.264853" stop-color="#DAE5FF" />
          <stop offset="0.374574" stop-color="#EDFFF4" />
          <stop offset="0.482763" stop-color="#DAE5FF" />
          <stop offset="0.565541" stop-color="#E6E1F1" />
          <stop offset="0.626097" stop-color="#FFE1E5" />
          <stop offset="0.669334" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_3072_10414"
          x1="2.39807"
          y1="28.2445"
          x2="28.5369"
          y2="46.8639"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.264853" stop-color="#DAE5FF" />
          <stop offset="0.374574" stop-color="#EDFFF4" />
          <stop offset="0.482763" stop-color="#DAE5FF" />
          <stop offset="0.565541" stop-color="#E6E1F1" />
          <stop offset="0.626097" stop-color="#FFE1E5" />
          <stop offset="0.669334" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_3072_10414"
          x1="2.39807"
          y1="28.2445"
          x2="28.5369"
          y2="46.8639"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.264853" stop-color="#DAE5FF" />
          <stop offset="0.346409" stop-color="#EDFFF4" />
          <stop offset="0.481204" stop-color="#DAE5FF" />
          <stop offset="0.582218" stop-color="#E6E1F1" />
          <stop offset="0.626097" stop-color="#FFE1E5" />
          <stop offset="0.669334" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
