import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Search = ({ size, fill, className, ...rest }: SvgIconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("h-18 w-18 text-text-color-02", className)}
      {...rest}
    >
      <title>Search</title>
      <path
        d="M9.59752 16.0492C7.76694 16.0492 6.21736 15.4142 4.94877 14.1443C3.68019 12.8744 3.0459 11.3421 3.0459 9.54755C3.0459 7.75298 3.68087 6.22073 4.9508 4.9508C6.22072 3.68088 7.75712 3.04593 9.56002 3.04593C11.3629 3.04593 12.8952 3.68088 14.1568 4.9508C15.4184 6.22073 16.0491 7.75423 16.0491 9.5513C16.0491 10.2734 15.9355 10.9649 15.7081 11.6256C15.4808 12.2862 15.1397 12.9062 14.685 13.4856L20.6991 19.4497C20.8691 19.6162 20.954 19.8226 20.954 20.0689C20.954 20.3152 20.8691 20.5233 20.6991 20.6932C20.5271 20.8671 20.3175 20.9541 20.0703 20.9541C19.8231 20.9541 19.6182 20.8671 19.4557 20.6932L13.4665 14.7101C12.9705 15.1311 12.3905 15.4596 11.7265 15.6954C11.0625 15.9313 10.3529 16.0492 9.59752 16.0492ZM9.57465 14.3459C10.9035 14.3459 12.0309 13.8772 12.9569 12.9398C13.8829 12.0024 14.3459 10.8717 14.3459 9.54755C14.3459 8.22345 13.8826 7.0927 12.956 6.1553C12.0294 5.21788 10.9023 4.74918 9.57465 4.74918C8.23308 4.74918 7.09344 5.21788 6.15572 6.1553C5.21801 7.0927 4.74915 8.22345 4.74915 9.54755C4.74915 10.8717 5.21772 12.0024 6.15485 12.9398C7.09197 13.8772 8.2319 14.3459 9.57465 14.3459Z"
        fill="currentColor"
      />
    </svg>
  );
};
