import { useQuery } from "@tanstack/react-query";

import { snack } from "design_system/Snackbar";

import { MERCHANT_QUERY_KEYS } from "repositories/merchant/keys";
import { merchantRepository } from "../merchant.repository";

type useMerchantInfoProps = {
  staleTime?: number;
  cacheTime?: number;
};
export const useMerchantInfo = (options?: useMerchantInfoProps) => {
  return useQuery({
    queryKey: MERCHANT_QUERY_KEYS.GET_INFO,
    staleTime: options?.staleTime ?? 1000 * 60 * 5,
    cacheTime: options?.cacheTime ?? 1000 * 60 * 5,
    queryFn: async () => {
      const response = await merchantRepository.getInfo();

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        merchantRepository.sentryLogError({
          error: response.error.message,
          feature: "useMerchantInfo",
          functionName: "merchantInfoQuery",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
