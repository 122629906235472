import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const Business = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.55371 21.1495C3.09371 21.1495 2.69488 20.9806 2.35721 20.6427C2.01938 20.3051 1.85046 19.9062 1.85046 19.4462V7.6255C1.85046 7.16383 2.01938 6.76358 2.35721 6.42475C2.69488 6.08575 3.09371 5.91625 3.55371 5.91625H7.86246V3.41625C7.86246 2.96041 8.03138 2.56258 8.36921 2.22275C8.70688 1.88291 9.10571 1.713 9.56571 1.713H14.4342C14.8942 1.713 15.293 1.88291 15.6307 2.22275C15.9685 2.56258 16.1375 2.96041 16.1375 3.41625V5.91625H20.4462C20.9079 5.91625 21.3081 6.08575 21.647 6.42475C21.986 6.76358 22.1555 7.16383 22.1555 7.6255V19.4462C22.1555 19.9062 21.986 20.3051 21.647 20.6427C21.3081 20.9806 20.9079 21.1495 20.4462 21.1495H3.55371ZM9.56571 5.91625H14.4342V3.41625H9.56571V5.91625ZM20.4462 14.6712H14.275V15.324C14.275 15.5657 14.193 15.7672 14.0292 15.9287C13.8652 16.0904 13.6629 16.1712 13.4222 16.1712H10.6207C10.38 16.1712 10.179 16.0894 10.0175 15.9257C9.8558 15.7621 9.77496 15.56 9.77496 15.3195V14.6712H3.55371V19.4462H20.4462V14.6712ZM11.275 14.6712H12.775V13.1712H11.275V14.6712ZM3.55371 13.1712H9.77496V12.5185C9.77496 12.2768 9.85688 12.0752 10.0207 11.9135C10.1847 11.752 10.387 11.6712 10.6277 11.6712H13.4292C13.6699 11.6712 13.871 11.7531 14.0325 11.9167C14.1941 12.0804 14.275 12.2824 14.275 12.5227V13.1712H20.4462V7.6255H3.55371V13.1712Z"
        fill={fill}
      />
    </svg>
  );
};
