import type { AxiosRequestConfig } from "axios";
import type { TPlans } from "features/Settings/Plan/shared/types";
import type { ApiResponse } from "repositories/_base/repository.types";
import type { MerchantInfoResponse } from "repositories/merchant/_types/MerchantInfoResponse";
import type { KybStatus } from "types/MerchantInfo";
import { Repository } from "../_base/repository";

class MerchantRepository extends Repository {
  constructor() {
    super({
      gateway: "/merchants", // This is the endpoint. Added to baseURL
    });
  }

  public async getInfo(options?: AxiosRequestConfig) {
    return this._http
      .get<MerchantInfoResponse>("/info", options) // This one returns RAW AxiosResponse
      .then(this.handleResponseWithoutData) // This one produces { success: true, data: { ... } }
      .catch(this.handleError); // This one produces { success: false, error: { ... } }
  }

  public async getMerchantKybStatus() {
    return this._http
      .get<
        ApiResponse<{
          kybStatus: KybStatus;
        }>
      >("/kyb_status", {
        params: {
          cache: false,
          forceUpdate: true,
          _: Date.now(),
        },
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async getPlanDetails() {
    return this._http
      .get<ApiResponse<TPlans>>("/plan_detail")
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const merchantRepository = new MerchantRepository();
