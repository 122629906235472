import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const DomainDisabled = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>DisabledDomainIcon</title>
      <g clip-path="url(#clip0_4081_23487)">
        <mask
          id="mask0_4081_23487"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="18"
          height="18"
        >
          <rect width="18" height="18" fill="currentColor" />
        </mask>
        <g mask="url(#mask0_4081_23487)">
          <path
            d="M16.5 6.74993V12.7499C16.5 12.9999 16.4219 13.1874 16.2656 13.3124C16.1094 13.4374 15.9375 13.4999 15.75 13.4999C15.5625 13.4999 15.3906 13.4343 15.2344 13.3031C15.0781 13.1718 15 12.9812 15 12.7312V6.74993H9.375C8.9625 6.74993 8.60938 6.60305 8.31563 6.3093C8.02188 6.01555 7.875 5.66243 7.875 5.24993V3.74993H6.01875C5.76875 3.74993 5.58125 3.6718 5.45625 3.51555C5.33125 3.3593 5.26875 3.18743 5.26875 2.99993C5.26875 2.81243 5.33125 2.64055 5.45625 2.4843C5.58125 2.32805 5.76875 2.24993 6.01875 2.24993H7.875C8.2875 2.24993 8.64063 2.3968 8.93438 2.69055C9.22813 2.9843 9.375 3.33743 9.375 3.74993V5.24993H15C15.4125 5.24993 15.7656 5.3968 16.0594 5.69055C16.3531 5.9843 16.5 6.33743 16.5 6.74993ZM14.85 16.9687L13.6125 15.7499H3C2.5875 15.7499 2.23438 15.6031 1.94062 15.3093C1.64687 15.0156 1.5 14.6624 1.5 14.2499V3.59993L1.05 3.14993C0.9125 3.01243 0.84375 2.83743 0.84375 2.62493C0.84375 2.41243 0.9125 2.23743 1.05 2.09993C1.1875 1.96243 1.3625 1.89368 1.575 1.89368C1.7875 1.89368 1.9625 1.96243 2.1 2.09993L15.9 15.8999C16.0375 16.0374 16.1094 16.2093 16.1156 16.4156C16.1219 16.6218 16.05 16.7999 15.9 16.9499C15.7625 17.0874 15.5906 17.1593 15.3844 17.1656C15.1781 17.1718 15 17.1062 14.85 16.9687ZM3 14.2499H4.5V12.7499H3V14.2499ZM3 11.2499H4.5V9.74993H3V11.2499ZM3 8.24993H4.5V6.74993H3V8.24993ZM6 14.2499H7.5V12.7499H6V14.2499ZM6 11.2499H7.5V9.74993H6V11.2499ZM9 14.2499H12.1125L10.6125 12.7499H9V14.2499ZM13.5 9.74993H12V8.24993H13.5V9.74993Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4081_23487">
          <rect width="18" height="18" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
