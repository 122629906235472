import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const MoreVert = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=more_vert, Size=24">
        <path
          id="Vector"
          d="M12.0009 20.2033C11.651 20.2033 11.3524 20.079 11.1052 19.8304C10.8579 19.5818 10.7343 19.283 10.7343 18.934C10.7343 18.5886 10.8584 18.2909 11.1067 18.0409C11.3549 17.7908 11.6534 17.6657 12.0021 17.6657C12.354 17.6657 12.6525 17.7917 12.8978 18.0437C13.1431 18.2956 13.2658 18.5949 13.2658 18.9415C13.2658 19.2881 13.1424 19.5851 12.8958 19.8323C12.6491 20.0796 12.3508 20.2033 12.0009 20.2033ZM12.0009 13.2657C11.651 13.2657 11.3524 13.1416 11.1052 12.8933C10.8579 12.6451 10.7343 12.3466 10.7343 11.9979C10.7343 11.646 10.8584 11.3475 11.1067 11.1022C11.3549 10.8569 11.6534 10.7342 12.0021 10.7342C12.354 10.7342 12.6525 10.8576 12.8978 11.1042C13.1431 11.3509 13.2658 11.6492 13.2658 11.9991C13.2658 12.349 13.1424 12.6476 12.8958 12.8948C12.6491 13.1421 12.3508 13.2657 12.0009 13.2657ZM12.0009 6.33425C11.651 6.33425 11.3524 6.20935 11.1052 5.95955C10.8579 5.70977 10.7343 5.4095 10.7343 5.05875C10.7343 4.70798 10.8584 4.40896 11.1067 4.16167C11.3549 3.91439 11.6534 3.79075 12.0021 3.79075C12.354 3.79075 12.6525 3.91557 12.8978 4.16522C13.1431 4.41486 13.2658 4.71506 13.2658 5.06582C13.2658 5.41659 13.1424 5.71568 12.8958 5.9631C12.6491 6.21053 12.3508 6.33425 12.0009 6.33425Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
