import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const SouthWest = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=south_west, Size=24">
        <path
          id="Vector"
          d="M5.83322 19.1135C5.56656 19.1135 5.34206 19.0224 5.15972 18.8402C4.97756 18.6579 4.88647 18.4334 4.88647 18.1667V9.9C4.88647 9.63333 4.97789 9.40883 5.16072 9.2265C5.34356 9.04416 5.56872 8.953 5.83622 8.953C6.10372 8.953 6.32789 9.04416 6.50872 9.2265C6.68972 9.40883 6.78022 9.63333 6.78022 9.9V15.8937L18.1702 4.5035C18.3539 4.31983 18.5749 4.228 18.8332 4.228C19.0916 4.228 19.3126 4.31983 19.4965 4.5035C19.6801 4.68733 19.772 4.90941 19.772 5.16975C19.772 5.43008 19.6801 5.65208 19.4965 5.83575L8.10622 17.2197H14.1C14.3666 17.2197 14.5911 17.3112 14.7735 17.494C14.9558 17.6768 15.047 17.902 15.047 18.1695C15.047 18.437 14.9558 18.6612 14.7735 18.8422C14.5911 19.0231 14.3666 19.1135 14.1 19.1135H5.83322Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
