import type { SvgIconProps } from "types/Icon";
import { cn } from "utils/classNames";

export const Today = ({ className, ...props }: SvgIconProps) => (
  <svg
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={cn("h-18 w-18", className)}
    {...props}
  >
    <title>Today</title>
    <path
      d="M6.64201 12.2143C6.13176 12.2143 5.70163 12.0382 5.35163 11.6859C5.00163 11.3337 4.82663 10.9024 4.82663 10.3922C4.82663 9.88192 5.00282 9.4518 5.3552 9.1018C5.70745 8.7518 6.1387 8.5768 6.64895 8.5768C7.15907 8.5768 7.58913 8.75299 7.93913 9.10536C8.28913 9.45761 8.46413 9.88886 8.46413 10.3991C8.46413 10.9092 8.28801 11.3393 7.93576 11.6893C7.58351 12.0393 7.15226 12.2143 6.64201 12.2143ZM3.41513 16.6121C3.07013 16.6121 2.77101 16.4854 2.51776 16.232C2.26438 15.9788 2.1377 15.6797 2.1377 15.3347V3.7903C2.1377 3.44405 2.26438 3.14386 2.51776 2.88974C2.77101 2.63549 3.07013 2.50836 3.41513 2.50836H4.59357V2.02367C4.59357 1.8443 4.65582 1.69274 4.78032 1.56899C4.90495 1.44524 5.05645 1.38336 5.23482 1.38336C5.41845 1.38336 5.57332 1.44524 5.69945 1.56899C5.82557 1.69274 5.88863 1.8443 5.88863 2.02367V2.50836H12.111V2.02367C12.111 1.8443 12.1733 1.69274 12.2979 1.56899C12.4224 1.44524 12.5739 1.38336 12.7523 1.38336C12.936 1.38336 13.0909 1.44524 13.2169 1.56899C13.343 1.69274 13.4061 1.8443 13.4061 2.02367V2.50836H14.5845C14.9308 2.50836 15.2309 2.63549 15.4851 2.88974C15.7393 3.14386 15.8664 3.44405 15.8664 3.7903V15.3347C15.8664 15.6797 15.7393 15.9788 15.4851 16.232C15.2309 16.4854 14.9308 16.6121 14.5845 16.6121H3.41513ZM3.41513 15.3347H14.5845V7.31249H3.41513V15.3347Z"
      fill="currentColor"
    />
  </svg>
);
