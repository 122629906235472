import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

/**
 * @deprecated Use from '@_icons' instead
 */
export const ArrowUpward = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="Name=arrow_upward, Size=24">
        <path
          id="Vector"
          d="M11.1483 7.09513L5.64672 12.5968C5.47642 12.7667 5.27642 12.8526 5.04672 12.8546C4.817 12.8566 4.61631 12.7726 4.44464 12.6027C4.27246 12.4328 4.18737 12.2322 4.18937 12.0011C4.19137 11.7699 4.27732 11.5687 4.44724 11.3973L11.3972 4.44728C11.4892 4.35634 11.5849 4.29013 11.6843 4.24865C11.7837 4.20715 11.8891 4.1864 12.0006 4.1864C12.1121 4.1864 12.2174 4.20715 12.3165 4.24865C12.4156 4.29013 12.511 4.35634 12.6027 4.44728L19.5587 11.4033C19.7286 11.5792 19.8136 11.7806 19.8136 12.0075C19.8136 12.2344 19.7286 12.4328 19.5587 12.6027C19.3847 12.7726 19.184 12.8576 18.9563 12.8576C18.7287 12.8576 18.5297 12.7726 18.3592 12.6027L12.8516 7.09513V19.2978C12.8516 19.5424 12.7708 19.7455 12.6092 19.9071C12.4476 20.0687 12.2445 20.1495 12 20.1495C11.7554 20.1495 11.5523 20.0687 11.3907 19.9071C11.2291 19.7455 11.1483 19.5424 11.1483 19.2978V7.09513Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
